import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  // current,
  createSlice
} from '@reduxjs/toolkit'

import _findIndex from 'lodash/findIndex'
import _head from 'lodash/first'
import _omit from 'lodash/omit'
import _set from 'lodash/set'

import {
  fetchTranscript as fetchTranscriptApi,
  updateTranscript as updateTranscriptApi
} from 'api/transcripts'

import {
  checkout
} from 'features/collections'

import {toast} from 'components/common/Toaster'

// Slice
export const transcriptssAdapter = createEntityAdapter()
const initialState = transcriptssAdapter.getInitialState({
  pendingRequests: []
})

// Selectors
const sliceSelector = state => state.transcripts
const {selectById} = transcriptssAdapter.getSelectors(sliceSelector)

export const selectCollectionTranscript = createSelector(
  [selectById],
  transcript => _omit(transcript, 'id')
)

export const selectCollectionWords = createSelector(
  [selectCollectionTranscript],
  ({words} = {}) => words || []
)

export const selectCollectionFirstWord = createSelector(
  [selectCollectionWords],
  _head
)

export const selectCollectionWordStartTimes = createSelector(
  [selectCollectionWords],
  words => words.map(word => word.start_time)
)

// Actions
export const fetchTranscript = createAsyncThunk(
  'transcripts/fetchOne',
  async ({collectionId, locale, trimmed}) => {
    try {
      const {data} = await fetchTranscriptApi({collectionId, locale})
      data.id = collectionId
      if (trimmed) {
        const bookmark = 1
        const type = 'pronunciation'
        const confidence = 1
        const lastTime = data.words[data.words.length - 1].end_time + 1
        const speaker = data.words[data.words.length - 1].speaker
        const skip = false
        data.words.push({skip, bookmark, 'start_time':lastTime, 'end_time':lastTime, 'alternatives':[{confidence,'content':'[CONTENT', speaker}],type})
        data.words.push({skip, bookmark, 'start_time':lastTime + 1, 'end_time':lastTime + 1, 'alternatives':[{confidence,'content':'TRIMMED', speaker}],type})
        data.words.push({skip, bookmark, 'start_time':lastTime + 2, 'end_time':lastTime + 2, 'alternatives':[{confidence,'content':'-', speaker}],type})
        data.words.push({skip, bookmark, 'start_time':lastTime + 3, 'end_time':lastTime + 3, 'alternatives':[{confidence,'content':'UPGRADE', speaker}],type})
        data.words.push({skip, bookmark, 'start_time':lastTime + 4, 'end_time':lastTime + 4, 'alternatives':[{confidence,'content':'REQUIRED]', speaker}],type})
        data.words.push({skip, bookmark, 'start_time':lastTime + 5, 'end_time':lastTime + 5, 'alternatives':[{confidence,'content':'', speaker}],type})
      }
      return data
    } catch (error) {
      toast.warn('Lost connection to server. Unable to complete your request. Try again?', {autoClose: 5000, toastId: 'connection-auth-warning'})
      console.log('unable to fetch transcripts', collectionId, error)
      throw new Error('Unable to fetch transcripts')
    }
  }
)

export const updateTranscript = createAsyncThunk(
  'transcripts/updateOne',
  async ({id, words, startTime, endTime, locale}, {getState, dispatch}) => {
    try {
      const state = getState()
      dispatch(checkout({collectionId: id, uid: state.firebase.auth.uid}))
      const {data} = await updateTranscriptApi({collectionId: id, data: {words, startTime, endTime}, locale})
      return data

    } catch ({response: {data: response}}) {
      console.log('could not complete patch request')
      let {message = '', data} = response || {}
      console.log(response)
      const {alternatives, startTime, word} = data || {}
      let wordString = (alternatives || [])[0]?.content || word || ''
      if (wordString) wordString = ` around "${wordString}"`
      if (startTime) {
        const el = document.querySelector(`.word[data-start_time="${startTime}"]`)
        if (el) el.classList.add('out-of-order')
        if (el?.previousSibling) el.previousSibling.classList.add('out-of-order')
        if (el?.previousSibling?.previousSibling) el.previousSibling.previousSibling.classList.add('out-of-order')
      }
      toast.error('Issue saving updates. ' + message + wordString + '. See text highlighted in red to correct.', {autoClose: false, toastId: 'out-of-order-errro'})
      console.log(message, data)
      throw new Error(message)
    }
  }
)

export const slice = createSlice({
  name: 'transcripts',
  initialState,
  reducers: {},
  extraReducers: builder => {
    const addRequestId = (state, {meta: {requestId}}) => {
      state.pendingRequests.push(requestId)
    }

    const removeRequestId = (state, {meta: {requestId}}) => {
      state.pendingRequests = state.pendingRequests.filter(id => id !== requestId)
    }

    builder
      .addCase(updateTranscript.fulfilled, (
        state,
        args
      ) => {
        // const {meta: {arg: {id, words, startTime, endTime}}} = args
        // const oldWords = selectCollectionWords({transcripts: state}, id)
        // const preSliceIndex = _findIndex(oldWords, ({start_time}) => start_time >= startTime)
        // const preSlice = oldWords.slice(0, preSliceIndex)
        // const postSliceIndex = _findIndex(oldWords, ({start_time}) => start_time >= endTime)
        // const postSlice = oldWords.slice(postSliceIndex)
        // _set(state, ['entities', id, 'words'], [...preSlice, ...words, ...postSlice])
        removeRequestId(state, args)
      })
      .addCase(updateTranscript.rejected, (state, args) => {
        toast.error(
          'Issues saving updates or problems connecting. Please try again.', 
          {
            autoClose: false, toastId:'issues-updating-transcript'
          })
        removeRequestId(state, args)
      })

    builder
      .addCase(fetchTranscript.fulfilled, (state, {meta, payload}) => {
        transcriptssAdapter.upsertOne(state, payload)
      })
      // .addCase(updateTranscript.pending, removeRequestId)
      .addCase(updateTranscript.pending, (
        state,
        args
      ) => {
        const {meta: {arg: {id, words, startTime, endTime}}} = args
        const oldWords = selectCollectionWords({transcripts: state}, id)
        const preSliceIndex = _findIndex(oldWords, ({start_time}) => start_time >= startTime)
        const preSlice = oldWords.slice(0, preSliceIndex)
        const postSliceIndex = _findIndex(oldWords, ({start_time}) => start_time >= endTime)
        const postSlice = oldWords.slice(postSliceIndex)
        
        // make sure it isnt out of order before pesistint
        let lastStartTime = 0
        const found = words.find(word => {
          if (word.start_time < lastStartTime) return true
          else {
            lastStartTime = word.start_time
            return false
          }
        })

        !found && _set(state, ['entities', id, 'words'], [...preSlice, ...words, ...postSlice])
        addRequestId(state, args)
      })
      .addCase('collections/fetch/fulfilled', (state, {meta, payload}) => {
        const {arg: {id: collectionId, versionId} = {}} = meta
        console.log('fetch fulfilled')
        if (!versionId) return

        const {items} = payload
        _set(state, ['entities', collectionId, 'words'], items)
      })
  }
})

const reducer = slice.reducer
export default reducer
