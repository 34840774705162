import React from 'react'
import axios from 'axios'
import _debounce from 'lodash/debounce'
import {getAuthTokenCookie} from 'utils'
import {getNewUserToken, getCurrentUser} from 'utils/Firebase'
import {getApiUrl, hasAAK} from 'utils/env'
import {toast, POSITION} from 'components/common/Toaster'

const imageAddr = 'https://uploads-ssl.webflow.com/5f8a27fa913a100a39ea46f9/61b4f5d1bab4634ba2bf77a5_download-speed%20copy.jpg'
const pixel = 'https://uploads-ssl.webflow.com/5f8a27fa913a100a39ea46f9/61b4fc4aa620201718512383_pixel.png'
const downloadSize = 3849043 // this must match with the image above
const params = {autoClose: false, toastId: 'internet-connection-issue', position: POSITION.TOP_CENTER}
let slowTimeout 

export const measureConnectionSpeed = async () => {
  // console.log('measuring speed.....')
  let startTime, endTime
  startTime = (new Date()).getTime()
  const cacheBuster = '?nnn=' + startTime

  const download = new Image()
  download.src = imageAddr + cacheBuster
  // this returns when the image is finished downloading
  // console.log('test URL', download.src)
  await download.decode()
  endTime = (new Date()).getTime()
  const duration = (endTime - startTime) / 1000
  const bitsLoaded = downloadSize * 8
  const speedBps = (bitsLoaded / duration).toFixed(2)
  var speedKbps = (speedBps / 1024).toFixed(2)
  const speedMbps = (speedKbps / 1024).toFixed(2)
  // console.log('speed is MB', speedMbps)
  return Math.round(Number(speedMbps))
}

export const checkOnlineStatus = async () => {
  try {
    if (!window.navigator.onLine) return false
  } catch (err) {
    console.log('Could not access navigator')// issue using navigator
  }
  
  try {
    const online = await fetch(pixel)
    return online.status === 200
  } catch (err) {
    return false // definitely offline
  }
}

const instance = axios.create({
  headers: {
    'Content-Type': 'application/json'
  },
  transformResponse: axios.defaults.transformResponse.concat(
    data => {
      try {
        const {result: {Items}, result} = data
        return Items || result
      } catch (error) {
        return data
      }
    }
  )
})

export const checkInternetOnline = async () => {
  const online = await checkOnlineStatus()
  if (!online) {
    toast.warn('We could not find an internet connection. Please re-fresh.', params)
    return false
  }
  return true
}

export const isGoodInternetSpeed = async (called = 0) => {  
  if (document.visibilityState !== 'visible') {
    toast.dismiss(params.toastId)
    return console.log('Tab not visible')
  }
  const speed = await measureConnectionSpeed()
  console.log('Speed:', speed, called, 'tries.')
  if (!speed || speed <= 10) {
    if (called < 2) {
      console.log('Slow speed. Checking again...')
      await isGoodInternetSpeed(called + 1)
    } else return false
  } else return true
}

const _checkInternetHealth = async () => {
  // only show speed issues on edit page
  if (window.location.pathname.indexOf('edit') === -1) return 

  const online = await checkInternetOnline()
  
  if (online) {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
      console.log('mobile...skipping speed test')

    } else {

      const lastCheckedStored = window.localStorage.getItem('last-checked-speed')
      const lastCheckedEpoch = parseInt(lastCheckedStored || 0, 10)
      const now = new Date().getTime()
      const timeSinceCheck = now - lastCheckedEpoch
      const maxWaitMills = 45 * 60 * 1000 // 45 mins in mills

      // set to now if not exists
      if (!lastCheckedStored) window.localStorage.setItem('last-checked-speed', now)

      // see if 30 mins has passed
      if (timeSinceCheck > maxWaitMills) {
        const message = ( 
          <div>
              We detected a slow internet connection. 
            <a 
              href='https://help.konch.ai/article/62-working-with-slow-internet-connections' 
              target='_blank' 
              style={{marginLeft: 6, textDecoration: 'underline'}} 
              rel="noreferrer"
            >
              Learn more
            </a>.
          </div>
        )  

        clearTimeout(slowTimeout)
        slowTimeout = setTimeout(() => {
          console.log('Timeout triggered before function completed.')
          toast.warn(message, params)
        }, 6000)
        const isGood = await isGoodInternetSpeed()  
        clearTimeout(slowTimeout)
        if (!isGood) toast.warn(message, params)
        else toast.dismiss(params.toastId)
        window.localStorage.setItem('last-checked-speed', now)
      } else {
        const lc = new Date(lastCheckedEpoch).toLocaleTimeString()
        const nc = new Date(lastCheckedEpoch + maxWaitMills).toLocaleTimeString()
        console.log('waiting to check speed. Last checked:', lc, 'Next Check:', nc)
      }
    }
  } else console.log('not online')
}

const checkInternetHealth = _debounce(_checkInternetHealth, 5000)

instance.interceptors.request.use(async function (options) {
  checkInternetHealth()
  const {uid, organization} = (await getCurrentUser(true))
  const {locale = 'us'} = organization || {}
  // console.log({data: options.data})
  const dataLocale = options.data?.locale
  const optionsLocale = typeof options.locale === 'string' ? options.locale : null
  const forceLocale = dataLocale || optionsLocale || false
  // console.log({forceLocale, options})
  const baseURL = getApiUrl(forceLocale || locale)
  
  let key = getAuthTokenCookie(locale)
  if (!key && uid) key = await getNewUserToken().id

  options.headers['key'] = key
  options.headers['id'] = uid
  if (hasAAK) options.headers['x-api-key'] = hasAAK
  options.baseURL = baseURL
  return options
}, function (error) {
  console.log('Request error: ', error)
  toast.info('Authorizing connection....', {autoClose: 5000, toastId: 'connection-auth-warning'})
  return Promise.reject(error)
})

instance.interceptors.response.use(null, async (error) => {
  console.log('Interceptors...', error?.response, error?.response?.status , error?.config)
  if (error && error.config && error.response && error.response.status === 403) {
    error.config.headers.key = (await getNewUserToken()).id
    console.log('issue connecting....', error.response, error.response.status , error.config)
    toast.info('Authorizing connection...', {autoClose: 5000, toastId: 'connection-auth-warning'})
    return axios.request(error.config)
  } 
  toast.dismiss('connection-auth-warning')
  console.log('Interceptors error', error?.response, error?.response?.status , error?.config)
  return Promise.reject(error)
})

const client = () => instance

export default client
