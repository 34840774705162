import React from 'react'
import _debounce from 'lodash/debounce'

import styled from 'styled-components'
import Modal from '@material-ui/core/Modal'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

import {Modal as ModalCard} from 'components/common/Overlay'
import Spinner from 'components/common/Spinner'
import useCollection from 'hooks/useCollection'
import useOrganization from 'hooks/useOrganization'

const UpgradeContent = styled.div`
  height: 100vh; 
  display: flex;
  .upgrade-text {
    display: inline-block;
    text-align: right;
    white-space: nowrap;
  }
  .MuiButton-root {
    display: flex;
    justify-content: space-between;
    padding-right: 0;
    background-color: ${({upgrading}) => upgrading ? 'lightgrey' : 'green'};
    color: #FFF;  
    :hover {
      background-color: #06a406;
    }
  }
  .fine-print {
     margin-top: 20px;
     p {
      color: #BBB;
      font-size: .75em;
      text-align: right;
      line-height: 1.7em;
      margin: 0;
     }
  }  
`

export const UpgradeModal = ({id, onClose}) => {
  const {onUpgrade, minutes, pricePerMin, collection: {orgId: oid}} = useCollection({id})
  const {billing} = useOrganization({oid})
  const {enterprise} = billing || {}

  const [upgrading, setUpgrading] = React.useState(false)

  const _handleUpgrade = async () => {
    if (upgrading) return
    setUpgrading(true)
    await onUpgrade()
    setUpgrading(false)
    onClose()
  }

  const handleUpgrade = _debounce(_handleUpgrade, 2000)

  return (
    <Modal
      open={true}
      onClose={onClose}
    >
      <UpgradeContent>
        <ClickAwayListener onClickAway={onClose}>
          <ModalCard style={{background: '#FFF', textAlign: 'left', padding: '20px 50px 30px', lineHeight: 2}}>
            <Box style={{width: '100%'}}>
              <h3>Request Precision&trade;</h3>
              <p>
              Precision&trade; service utilizes certified 
              humans to correct mistakes in your 
                {
                  enterprise ?
                    ' transcription.' : 
                    ` transcription for $${pricePerMin.toFixed(2)} per minute of audio.` 
                } 
              </p>
              <div className='actions-cont' style={{marginTop: enterprise ? 10 : 30}}>
                <Button 
                  onClick={() => {
                    setUpgrading(true)
                    handleUpgrade()
                  }} 
                  variant='contained' 
                  disabled={upgrading}
                  disableElevation
                >
                  <div style={{width: 15}}/>
                  <div>
                    <span className='upgrade-text'>
                      {upgrading ? 'Upgrading' : 'Upgrade'} to Precision&trade; 
                    </span> 
                    {
                      enterprise ? '' : ` for ${(minutes * pricePerMin).toFixed(2)}`
                    }

                  </div>
                  <div style={{width: 45, display: 'flex', justifyContent: 'center'}}>
                    {upgrading && <Spinner light small />}
                  </div>
                </Button>
              </div>
              {
                !enterprise && (
                  <div className='fine-print'>
                    <p>Depending on difficulty of audio a 10-30% fee could be added upon completion.</p>
                    <p>Min $8 charged per request. Difference is credited. Existing credits offset charges.</p>
                  </div>
                )
              }
              
            </Box>
          </ModalCard>
        </ClickAwayListener>
      </UpgradeContent>
    </Modal>
  )
}

export default UpgradeModal