import {useState} from 'react'
import {getSubscription, getInvoices, getPaymentMethods, getAdjustments} from 'utils/Firebase'

// TODO: convert state to redux store
export default (props) => {
  const {customer: customerId, subscription: subscriptionId} = props = {}
  const [subscription, setSubscription] = useState(null)
  const [paymentMethods, setPaymentMethods] = useState(null)
  const [invoices, setInvoices] = useState(null)
  const [adjustments, setAdjustments] = useState(null)

  const fetchSubscription = async (subscriptionIdParam) => {
    const sid = subscriptionIdParam || subscriptionId
    if (!sid) {
      setSubscription({})
      return
    }
    try {
      const data = await getSubscription(sid)
      if (!data) return setSubscription({})
      const s = {...data, id: sid}
      setSubscription(s)
      return s
    } catch (error) {
      console.log('error', error)
    }
    return subscription
  }

  const fetchInvoices = async (customerIdParam) => {
    const cid = customerIdParam || customerId 
    console.log({cid})
    setInvoices(null)
    if (!cid) return
    const invoices = await getInvoices(cid) || []
    setInvoices(invoices)
    return invoices
  }

  const fetchAdjustments = async (customerIdParam) => {
    const cid = customerIdParam || customerId 
    console.log({cid})
    setAdjustments(null)
    if (!cid) return
    const adjustments = await getAdjustments(cid) || 0
    setAdjustments(adjustments)
    return adjustments
  }

  const fetchPaymentMethods = async (customerIdParam) => {
    const cid = customerIdParam || customerId 
    console.log({cid})
    setPaymentMethods(null)
    if (!cid) return
    const paymentMethods = await getPaymentMethods(cid) || []
    console.log({paymentMethods})
    setPaymentMethods(paymentMethods)
    return paymentMethods
  }

  return {
    fetchSubscription,
    fetchInvoices,
    fetchPaymentMethods,
    fetchAdjustments,
    subscription,
    invoices,
    paymentMethods,
    adjustments
  }
}
