import React, {useState, useEffect, Fragment} from 'react'
import FadeIn from 'react-fade-in'
import {Link as LinkTo} from 'react-router-dom'
import styled from 'styled-components'
import qs from 'query-string'
import SSOIcon from '@material-ui/icons/Security'
import {useForm} from 'react-hook-form'

import Button from '@material-ui/core/Button'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import FormatQuoteIcon from '@material-ui/icons/FormatQuoteRounded'

import {PopUpAuth, registerByEmailPass, fetchUrlForPromotion, adminImpersonateLogin} from 'utils/Firebase'
import {isIMP} from 'utils/env'
import GoogleIcon from 'static/images/google-icon.png'
import LogoImg from 'static/images/logo_bw_ish.png'
import {toast} from 'components/common/Toaster'
import Spinner from 'components/common/Spinner'
import useProfile from 'hooks/useProfile'

const ShowPasswordIconOn = styled(VisibilityIcon)`
  min-width: 48px;
  color: #999;
`

const GeneralError = styled.div`
  height: 25px;
  color: #911;
  font-size: .8em;
  text-transform: uppercase;
`

const ShowPasswordIconOff = styled(VisibilityOffIcon)`
  min-width: 48px;
  color: #999;
`

const ResponsiveAccent = styled.div`
  @media (max-width: 1292px) {
    display: none;
  }
  @media (max-height: 880px) {
    display: none;
  }
`

const Brands = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 20px 40px;
  opacity: .5;
  & img {
    margin: 0 20px 20px;
    max-width: 150px;
  }

  @media (min-width: 1001px) {
    @media (max-width: 1350px) {
      & img:first-child {
        display: none;
      }
    }

    @media (max-width: 1100px) {
      & img:nth-child(2) {
        display: none;
      }
    }
  }
  @media (max-height: 450px) {
    display: none;
  }

`

const Root = styled.div``

const Logo = styled.div`
  position: absolute;
  padding: 20px 0 0 20px;
  opacity: 0.5;
  & img {
    width: 80px;
  }
  @media (max-width: 1000px) {
    & img {
      width: 49px;
    }
  }
`

const Cont = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .left {
    max-width: 740px;
  }  
  & button {
    text-transform: initial;
    width: 100%;
    padding: 10px;
  }
  @media (max-width: 1000px) {
    flex-direction: column;
    & .left, .right {
      max-height: 800px;
    }
    & .right {
      
    }
  }
  position: relative;
`

const Panel = styled.div`
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  &.right {
    background: #fff1e5;
    display: flex;
    align-items: left;
    flex-direction: column;
    position: relative;
    @media (max-width: 780px) {
      background-size: 143px;
    }
  }
`

const Form = styled.form`
  display: flex;
  justify-content: left;
  align-items: center;
  flex-direction: column;
  width: 311px;
  text-align: left;
  p {
    text-align: center;
  }
  input {
    text-align:left;
    display: block;
    padding: 6px 0 3px 0;
    width: 100%;
    padding: 10px;
    margin-bottom: 8px;
    border: 1px solid #CCC;
    border-radius: 4px;
  }
  .MuiButton-containedPrimary {
    background-color: #4fae32;
    margin-top: 10px;
  }
`

const InputCont = styled.div`
  width: 100%;
  position: relative;
  input {
    padding-right: 50px;
  }
  div {
    position: absolute;
    top: 6px;
    right: 0;
  }
`

const H1 = styled.h1`
  margin-bottom: 35px;
  font-weight: 100;
  color: #444;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: left;
  width: 100%;
`

const OrCont = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0 15px;
  width: 100%;
`

const OrLine = styled.div`
  border-bottom: 1px solid #CCC;
  flex: 1;
`

const Or = styled.div`
  border-bottom: none;
  flex: 0;
  padding: 0 20px;
  font-size: .9em;
  white-space: nowrap;
  color: #888;
`

const Quote = styled.div`
  margin: -20px 30px;
  max-width: 600px;
  & p {
    margin: 5px 0;
  }
  & b {
    line-height: 1.6em;
    font-size: 1.4em;
  }


`

const QuoteIcon = styled(FormatQuoteIcon)`
  font-size: 9em !important;
  color: #aba49e;
  margin-left: -40px;
  transform: rotate(180deg);
`

const Credit = styled.div`
  color: rgba(0,0,0,.5);
`

const TextContainer = styled.div`
  width: 70%;
  z-index: 1;
  margin-top: -40px;
`

const A = styled.a`
  color: #2f85bb;
  :hover {
    text-decoration: underline;
  }
`

const Link = styled(LinkTo)`
  color: #2f85bb;
  :hover {
    text-decoration: underline;
  }
`

const Footer = styled.div`
  color: #777;
  padding: 10px;
  font-size: .9em;
  line-height: 1.5em;
  text-align: center;
`

const Input = styled.input`
  ${({error}) => error && 'border-color: #e25d5d;'}
`

const Label = styled.label`
  display: flex;
  justify-content: space-between;
  text-align:left;
  padding: 8px 0 6px 0;
  width: 100%;
  margin-left: 7px;
  color: #333;
  font-size: .9em;
  ${({error}) => error && 'color: #e25d5d;'}
`

const ErrorLabel = styled.span`
  min-height: 20px;
  font-size: .9em;
  &:empty {
    opacity: 0;
    transition: opacity 1s;
  }
  opacity: 1;
  color: #e25d5d;
  text-align: right;
  transition: opacity 1s;
  margin-right: 9px;
`

// <ErrorLabel direction='down'>{issues.all?.message}</ErrorLabel>
// watch()

const EnterprisePanel = ({settings}) => {
  return (
    <Panel className='right' style={settings}>
      {
        settings.logo ?
          <img src={settings.logo} alt='custom organization logo' style={settings.logoStyle || {maxHeight: '90%', maxWidth: '80%'}} /> :
          <h1>{settings.name}</h1>
      }
    </Panel>
  )
}
const DefaultPanel = () => (
  <Panel className='right'>
    <TextContainer>
      <Row><H1 style={{textAlign: 'left', zIndex: 1, width: '70%', minWidth: '370px'}}>Don't take our word for it...</H1></Row>
      <Row><QuoteIcon /></Row>
      <Quote>
        <p>
          <b>
            I’m a man of few words so I’ll keep this short….love it!
            Clean, intuitive, fast and easy to consume. I ended up playing longer than expected.
          </b>
        </p>
        <br />
        <Credit>
          <p>Damir I.</p>
          <p>Speechmatics</p>
        </Credit>
      </Quote>
    </TextContainer>
    <ResponsiveAccent>

      <div style={{
        position: 'absolute',
        right: '40px',
        top: 0,
        opacity: 0.8
      }}>
        <img
          src='https://uploads-ssl.webflow.com/5f8a27fa913a100a39ea46f9/5f8a27fa2678055fb9a4e317_Accent%2006.svg'
          alt='accent top'
        />
      </div>
      <img
        style={{
          position: 'absolute',
          left: 0,
          top: '74%',
          opacity: 0.8
        }}
        src='https://uploads-ssl.webflow.com/5f8a27fa913a100a39ea46f9/5f8a27fa267805a4f2a4e2f8_Accent%2007.svg'
        alt='top art'
      />
    </ResponsiveAccent>
    <div style={{
      position: 'absolute',
      left: 0,
      top: '4%',
      opacity: 0.3
    }}>
      <img
        style={{width: '400px'}}
        src='https://uploads-ssl.webflow.com/5f8a27fa913a100a39ea46f9/5ff247a24d31c90e23703630_accent_no_black.svg'
        alt='accent left'
      />
    </div>
    <ResponsiveAccent style={{
      position: 'absolute',
      right: 0,
      top: '50%',
      opacity: 0.5
    }}>
      <img
        src='https://uploads-ssl.webflow.com/5f8a27fa913a100a39ea46f9/5ff248ebc8595f5875ba3e37_accent_right_no_black.svg'
        alt='accent right'
        style={{width: '300px'}}
      />
    </ResponsiveAccent>
  </Panel>
)

export default (props) => {
  const {history, settings = {}} = props
  const {profile} = useProfile()
  const [processing, setProcessing] = useState(false)
  const [processingSSO, setProcessingSSO] = useState(false)
  const [generalError, setGeneralError] = useState('')

  const {register, handleSubmit, errors, setError} = useForm({
    mode: 'onBlur',
    shouldFocusError: true
  })
  const [showPassword, setShowpassword] = useState(false)

  const onSubmit = async ({email, password, name}) => {
    if (processing) return
    if (email.includes('+') && !email.split('@').slice(1).join('').match(/^(konch\.ai|ops\.getwingapp\.com|gitstart.com)$/i)) {
      return setError('email', {type: 'manual', message: '"+" is not allowed'})
    }
    setProcessing(true)
    const {error, message} = await registerByEmailPass({email, password, name})
    if (!error) {
      history.push('/dashboard')
    } else {
      if (message.indexOf('The email address is already in use') >= 0) {
        setError('email', {type: 'manual', message: 'Email already taken.'})
        setGeneralError(<span>Login instead? <Link to='/signin'>Click here.</Link></span>)
      } else {
        console.log(error, message)
        toast.error(message, {autoClose: 5000})
        setGeneralError(<span>No account? <Link to='/register'>Register here.</Link></span>)
      }
    }
    setProcessing(false)
  }

  const onSSOAuth = async (provider) => {
    setProcessingSSO(true)
    try {
      const popupResult = await PopUpAuth(provider, settings.organization)
      console.log('popupResult', JSON.stringify(popupResult, null, 3))
      history.push('/dashboard')
    } catch (error) {
      console.log('popupResult', error)
      toast.error(error.message, {autoClose: 5000})
    }
    setProcessingSSO(false)
  }

  const forceLogin = async (key) => {
    const allowed = await adminImpersonateLogin(key)
    console.log({allowed})
    if (allowed) history.push('/dashboard')
  }

  useEffect(() => {
    document.title = 'Register Free with Konch'
    fetchUrlForPromotion()
    const {key} = qs.parse(props.location.search.substr(1))
    console.log({isIMP, key})
    if (isIMP && key) forceLogin(key, history)
    return () => {
      document.title = 'Konch'
    }
  }, [])

  useEffect(() => {
    const skipAutoLogin = localStorage.getItem('konch-skip-auto-login')
    if (profile.isLoaded && !profile.isEmpty) {
      const page = `/${profile?.settings?.homePage || 'dashboard'}`
      console.log('forward to', page)
      !skipAutoLogin && history.push(page)  
    }
  }, [profile.isLoaded])

  return (
    <Root>
      {!settings.active && <Logo><img src={LogoImg} width='100px' alt='logo' /></Logo>}
      <Cont>
        <Panel className='left'>
          <Form noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
            <H1><b>Register</b> an account</H1>
            {/* if not enterprise, or is enterprise and allows google*/}
            <div style={{display: 'flex', width: '100%'}}>
              {
                (!settings.active || settings.allowGoogle) &&
                  <Button
                    variant='contained'
                    color='secondary'
                    disableElevation
                    style={{backgroundColor: '#FFF', color: '#333', border: '1px solid #ccc'}}
                    startIcon={
                      processingSSO ? <Spinner small /> : <img src={GoogleIcon} width='20px' height='20px' alt='google icon' />
                    }
                    onClick={() => onSSOAuth('google')}
                  >
                    Register using Google
                  </Button>
              }
              {
                settings.provider &&
                  <Button
                    variant='contained'
                    color='secondary'
                    disableElevation
                    style={{backgroundColor: '#FFF', color: '#333', border: '1px solid #ccc'}}
                    startIcon={
                      processingSSO ? <Spinner small /> : <SSOIcon style={{width:'20px', height:'20px'}} alt='sso icon' />
                    }
                    onClick={() => onSSOAuth(settings.provider)}
                  >
                    Register using SSO
                  </Button>
              }
            </div>

            {
              (!settings.active || settings.allowEmail) && (
                <>
                  {
                    (!settings.active || settings.provider || settings.allowGoogle) &&
                       <OrCont><OrLine /><Or>or sign in via email</Or><OrLine /></OrCont>
                  }
                  <GeneralError>{generalError && <FadeIn>{generalError}</FadeIn>}</GeneralError>
                  <Label error={errors.name}>Name <ErrorLabel>{errors.name && ` ${errors.name.message}`}</ErrorLabel></Label>
                  <Input
                    name='name'
                    type='text'
                    error={errors.name}
                    className={errors.name ? 'small-shake' : ''}
                    autoComplete='off'
                    ref={register({
                      required: 'Please enter a name',
                      pattern: {
                        value: /^([a-zA-Z]{2,}(\s)*)+$/i,
                        message: 'Please use a real name'
                      }
                    })} />

                  <Label error={errors.email}>Email <ErrorLabel>{errors.email && ` ${errors.email.message}`}</ErrorLabel></Label>
                  <Input
                    name='email'
                    type='email'
                    error={errors.email}
                    className={errors.email ? 'small-shake' : ''}
                    autoComplete='off'
                    ref={register({
                      required: 'Please enter a valid email',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: 'Invalid email address'
                      }
                    })} />

                  <Label error={errors.password}>Password <ErrorLabel>{errors.password && ` ${errors.password.message}`}</ErrorLabel></Label>
                  <InputCont>
                    <Input
                      name='password'
                      type={showPassword ? 'text' : 'password'}
                      error={errors.password}
                      className={errors.password ? 'small-shake' : ''}
                      autoComplete='off'
                      ref={register({
                        required: 'Please enter a password.',
                        minLength: {value: 6, message: 'Must be at least 6 characters'}
                      })} />
                    <div>
                      <span
                        onClick={() => setShowpassword(!showPassword)}
                        tabIndex={0}
                        role="button" 
                        styling="text"
                        onKeyDown={(evt) => evt.keyCode === 13 && setShowpassword(!showPassword)}
                        style={{outline: 'none'}}
                      >
                        {
                          showPassword
                            ? <ShowPasswordIconOn />
                            : <ShowPasswordIconOff />
                        }
                      </span>
                    </div>
                  </InputCont>
                  <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                    style={{backgroundColor: settings.buttonColor || '#2091d0', fontSize: '1em', height: '48px'}}
                    disableElevation
                    onClick={(evt) => { console.log(evt) }}>
                    { processing ? <Spinner small color='#FFF' /> : 'Create your account' }
                  </Button>
                  <Footer>
                      Already have an account? <Link to='/signin'>Login</Link>
                    <p>By registering, I agree to Konch's <br />
                      <A href='https://www.konch.ai/terms-and-conditions'>Terms</A>
                        &nbsp;and&nbsp;
                      <A href='https://www.konch.ai/privacy-policy'>Privacy Policy</A>
                    </p>
                  </Footer>
                </>
              )}
          </Form>
        </Panel>
        {
          settings.active
            ? <EnterprisePanel {...props} />
            : (
              <Fragment>
                <DefaultPanel />
                <Brands>
                  <img
                    src='https://uploads-ssl.webflow.com/5f8a27fa913a100a39ea46f9/5f8d12448490465fc6ee8e35_uni_nyu.png'
                    width='100px' alt='Konch customer - New York University' />
                  <img
                    src='https://uploads-ssl.webflow.com/5f8a27fa913a100a39ea46f9/5f8d1316d401615bf2e5ff3d_uni_tamu.png'
                    alt='Konch customer - Texas A&M' />
                  <img
                    src='https://uploads-ssl.webflow.com/5f8a27fa913a100a39ea46f9/5f8d10f94e5fcea31ab6b49e_lse.png' width='90px'
                    alt='Konch customer - LSE London' />
                  <img
                    src='https://uploads-ssl.webflow.com/5f8a27fa913a100a39ea46f9/5f8d10f834c9a0422eca20dd_uni_columbia.png'
                    alt='Konch customer - Columbia' />
                </Brands>
              </Fragment>
            )
        }
      </Cont>
    </Root>

  )
}
