import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/storage'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import {trackCustom, trackIdentity} from 'utils/Tracker'
// import getKonch from 'utils/KonchSDK'
import {v4 as uuidv4} from 'uuid'
import axios from 'axios'
import {saveAs} from 'file-saver'
// import _debounce from 'lodash/debounce'

import {getEmailKey} from 'features/profile'
import {FIREBASE_CONFIG, isDev, FUNC_LOCAL_DEV, LOCAL_FB_FUNC_URL, FB_REGION, isProd, getApiUrl, hasAAK} from 'utils/env'
import {setAuthTokenCookie, getAuthTokenCookie, removeAuthTokenCookie} from 'utils'
import qs from 'query-string'
import * as Sentry from '@sentry/react'

import CryptoJS  from 'crypto-js'

const CRYPTO_KEY = '6D9CS28F426EF24111A870742T7BA7CX71EB'

// import {updateProfileStore} from 'features/profile'
// import store from 'store'
// const stringHash = require('string-hash')
const app = firebase.initializeApp(FIREBASE_CONFIG)
firebase.firestore()

console.log({FUNC_LOCAL_DEV, LOCAL_FB_FUNC_URL})
export const fb = firebase
export const FieldValue = firebase.firestore.FieldValue
export const db = firebase.firestore()
export const fbDbRef = firebase.database()
export const firebaseAuth = firebase.auth
export const storageRef = firebase.storage().ref()

if (FUNC_LOCAL_DEV) app.functions().useFunctionsEmulator(LOCAL_FB_FUNC_URL)
export const fbFunc = FUNC_LOCAL_DEV ? firebase.functions() : firebase.app().functions(FB_REGION)

export const GoogleProvider = new firebaseAuth.GoogleAuthProvider()
GoogleProvider.setCustomParameters({
  prompt: 'select_account'
})

const EU = ['AT', 'BE', 'BG', 'CY', 'CZ', 'DK', 'DE', 'EE',
  'ES', 'FI', 'FR', 'GB', 'GR', 'HU', 'HR', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'PO', 'PT', 'RO', 'SE', 'SI', 'SK']

// console.log('Firebase', FIREBASE_CONFIG, FUNCTIONS_URL, isDev)

firebaseAuth().setPersistence(firebaseAuth.Auth.Persistence.LOCAL)

// export const getCorrectionsCount = async (profile, locale, langCode) => {
//   const {body = {}} = await getKonch(profile).getCorrectionsCount(locale, langCode)
//   const {result = {}} = body
//   return result.total || 0
// }

// export const test = async () => {
//   const session = await getKonch().createTempUser(uuidv4(), 'eu', uuidv4())
//   console.log(session)
//   return session
// }

// export const getCorrection = async (profile, locale, languageCode) => {
//   const data = await getKonch(profile).getCorrection(locale, languageCode)
//   // Mock
//   // const data = {body: mock, response: {statusCode: 200}}
//   let {response, body} = data

//   if (response.statusCode > 200) return this.props.history.push('/404')
//   const {correction = {}, receiptHandle} = (body.result || {})
//   if (!receiptHandle) return null
//   // console.log('GET Correction', correction, receiptHandle)
//   const duration = correction.items[correction.items.length - 1].end - correction.items[0].start
//   // console.log('duration', duration)
//   const collection = {
//     id: correction.id,
//     name: 'Correcting...',
//     languageCode,
//     offset: correction.items[0].start_time,
//     tProgress: 100,
//     mp3: correction.audio,
//     video: correction.video,
//     smallVideo: correction.video,
//     tCompletion: {draft: true},
//     props: {duration},
//     transcript: {
//       transcripts: [{transcript: ''}],
//       speakers: correction.speakers,
//       words: correction.items
//     }
//   }
//   // console.log('correctionTranslation', correction.speakers)
//   // console.log('correctionTranslation', JSON.stringify(collection, null, 2))
//   return {collection, receiptHandle}
// }

// export const handleUpdateWords = async (profile, locale, correction) => {
//   try {
//     const response = await getKonch(profile).postCorrection(locale, correction)
//     return response.result
//   } catch (err) {
//     console.error('Error Updating Transcript', err)
//   }
// }

// export const handleCompleteCorrection = async (profile, locale, correction, receiptHandle, completionTime) => {
//   console.log('completionTime container', completionTime)
//   const data = await getKonch(profile).completeCorrection(locale, correction, receiptHandle, completionTime)

//   return data
// }

// export const checkCollectionBackIn = async (cid) => {
//   if (window.location.pathname.startsWith('/correct')) return {}
//   const profile = await getCurrentUser(firebaseAuth().currentUser.uid)
//   const data = await getKonch(profile).checkCollecionBackIn(cid)
//   return data
// }

// export const loadNextCorrection = async (profile, langCode) => {
//   const response = getKonch(profile).getCorrection(langCode)
//   const correctionResult = (response.body || {}).result
//   const correction = correctionResult.message ? null : correctionResult
//   console.log('correction', correction)
// }

export const userConnect = () => {
  db.collection('cities').doc('SF').onSnapshot(function (doc) {
    console.log('Current data: ', doc.data())
  })
}

export const updateLanguage = (uid, language) => {
  return db.collection('Users').doc(uid).update({
    'settings.defaultLanguage': language
  })
}

export const registerByEmailPass = async ({email, password, name}, cb) => {
  try {
    const result = await firebaseAuth().createUserWithEmailAndPassword(email, password)
    const user = result.user
    cb && cb()
    console.log('## creating user....', JSON.stringify(user))
    await addUserIfDoesNotExist({...user, name})
    console.log('registered. now logging in...')
    return {}
  } catch ({message}) {
    return {error: true, message}
  }
}

export const stampLoginTime = ({uid}) => {
  const lastSignInTime = new Date().getTime()
  return db.collection(`Users`).doc(uid).update({lastSignInTime})
}

export const loginByEmailPass = async ({email, password}) => {
  try {
    const {user} = await firebaseAuth().signInWithEmailAndPassword(email, password)
    const u = user.toJSON()
    await stampLoginTime(u)
    return u
  } catch ({message}) {
    return {error: true, message}
  }
}

export const verifyPasswordResetCode = async (code) => {
  try {
    await firebaseAuth().verifyPasswordResetCode(code)
    return {}
  } catch ({message}) {
    return {error: true, message}
  }
}

export const sendPasswordResetEmail = async ({email, password}) => {
  try {
    await firebaseAuth().sendPasswordResetEmail(email)
    return {}
  } catch ({message}) {
    return {error: true, message}
  }
}

export const resetPasswordWithCode = async ({code, password: newPassword}) => {
  try {
    await firebaseAuth().confirmPasswordReset(code, newPassword)
    return {}
  } catch ({message}) {
    return {error: true, message}
  }
}

export const getAvatars = async () => {
  const ref = storageRef.child('Avatars/stock')
  try {
    const images = await ref.listAll()
    return Promise.all(images.items.map(image => storageRef.child(image.fullPath).getDownloadURL()))
  } catch (error) {
    console.log('images', error)
  }
}

export const getOrganization = async ({organization}) => {
  if (organization === undefined) return
  const oid = typeof organization === 'string' ? organization : organization.uid
  return {...(await db.collection('Organizations').doc(oid).get()).data(), uid: oid}
}

// get a user that is not easily fetched with DB rules
export const getSecuredUser = async (uid, loadOrg = false) => {
  try {
    const {data} = await fbFunc.httpsCallable('getUser')({uid, loadOrg})
    return data
  } catch (error) {
    console.log('Could not get user', uid)
  }
  return false
}

// get a user that is not easily fetched with DB rules
export const updateEmail = async (email) => {
  if (!email) {
    console.log('no email')
    return {}
  }
  try {
    const response = await fbFunc.httpsCallable('updateEmail')({email})
    console.log({response})
    return response
  } catch ({code, message, details}) {
    console.log('Issue updating', code, message, details)
    return {error: code}
  }
}

export const logEvent = async ({uid, oid, event}) => {
  if (!oid || !uid || !event) return console.log('DID NOT LOG EVENT. MISSING PARAMS', {uid, oid, event})
  try {
    await fbFunc.httpsCallable('logEvent')({uid, oid, event})
  } catch (error) {
    console.log('Could not download report', uid)
  }
  return 
}

// get a user that is not easily fetched with DB rules
export const downloadReport = async ({uid, oid, span, maxSpan, filter}) => {
  try {
    const {data} = await fbFunc.httpsCallable('downloadReport')({uid, oid, span, maxSpan, filter})
    console.log({data})
    var blob = new Blob([data], {type: 'text/csv;charset=utf-8'})
    saveAs(blob, `${oid}_${span}mo_report.csv`)
    return 
  } catch (error) {
    console.log('Could not download report', uid)
  }
  return false
}

// get a user that is not easily fetched with DB rules
export const downloadCorrectorsReport = async ({uid, languageCode = 'en', all, previousMonths}) => {
  const lang = languageCode.split('-')[0].toLowerCase()
  var d = new Date()
  var startEpoch = new Date(d.getFullYear(), d.getMonth() - previousMonths, 1).getTime()
  var endEpoch = new Date(d.getFullYear(), d.getMonth() + 1 - previousMonths, 0).getTime()
  try {
    const {data} = await fbFunc.httpsCallable('downloadCorrectorsReport')({lang, uid, all, startEpoch, endEpoch})
    console.log({data})
    const date = new Date().toISOString().split('T')[0]
    var blob = new Blob([data], {type: 'text/csv;charset=utf-8'})
    saveAs(blob, `correction_report_${languageCode}_${date}.csv`)
    return 
  } catch (error) {
    console.log('Could not download report')
  }
  return false
}

// get a user that is not easily fetched with DB rules
export const removeUserFromOrg = async ({oid, uid}) => {
  try {
    const {data} = await fbFunc.httpsCallable('removeUserFromOrg')({oid, uid})
    return data
  } catch (error) {
    console.log('Could not get subscription', {oid, uid})
  }
  return null
}

/**
 * Get subscription information based on subscription id
 * @param subscriptionId 
 * @returns subscription
 */
export const getSubscription = async (subscriptionId) => {
  try {
    const {data} = await fbFunc.httpsCallable('getSubscription')({subscriptionId})
    return data
  } catch (error) {
    console.log('Could not get subscription', subscriptionId)
  }
  return null
}

/**
 * Get a customer's credits
 * @param customerId Customer Id
 * @returns number of credits
 */
export const getCredits = async (customerId) => {
  console.log('getCredits', customerId)
  if (!customerId) return null
  try {
    const {data} = await fbFunc.httpsCallable('getCredits')({customerId})
    return data
  } catch (error) {
    console.log('Could not get credits', customerId)
  }
  return []
}

/**
 * Get list a customer's payment methods
 * @param customerId Customer Id
 * @returns a list of PaymentMethods for a given customer id
 */
export const getPaymentMethods = async (customerId) => {
  if (!customerId) return []
  try {
    const {data} = await fbFunc.httpsCallable('getPaymentMethods')({customerId})
    return data
  } catch (error) {
    console.log('Could not get payment methods', customerId)
  }
  return []
}

/**
 * Get list a customer's credit and transaction adjustments
 * @param customerId Customer Id
 * @returns a list of credits and transaction for a given customer id
 */
export const getAdjustments = async (customerId) => {
  if (!customerId) return []
  try {
    const {data} = await fbFunc.httpsCallable('getAdjustments')({customerId})
    return data
  } catch (error) {
    console.log('Could not get payment methods', customerId)
  }
  return []
}

/**
 * Get list a customer's invoices
 * @param customerId Customer Id
 * @returns a list of invoices
 */
export const getInvoices = async (customerId) => {
  if (!customerId) return []
  try {
    const {data} = await fbFunc.httpsCallable('getInvoices')({customerId})
    return data
  } catch (error) {
    console.log('Could not get invoices', customerId)
  }
  return []
}

/**
 * Get user firestore info when forestore rules does not allow for access based on rules
 * @param loadOrg (loads related object organization linked to user, otherwise it will just return OID)
 * @returns user object
 */
export const getCurrentUser = async (loadOrg = false) => {
  const uid = firebaseAuth()?.currentUser?.uid
  if (!uid) return {}
  const user = await getUser(firebaseAuth().currentUser.uid, loadOrg)
  return user
}

/**
 * Get user firestore info when forestore rules does not allow for access based on rules
 * @param uid (user id) loadOrg (loads related object organization linked to user, otherwise it will just return OID)
 * @returns user object
 */
export const getUser = async (uid, loadOrg = false) => {
  // console.log('getUser', uid, loadOrg)
  try {
    const snap = (await db.collection('Users').doc(uid).get())
    const user = {...snap.data(), uid}
    // console.log('getUser', user.organization)
    if (loadOrg) user.organization = await getOrganization(user)
    return user
  } catch ({message}) {
    // wasn't able to get it locally, let's try remotely
    console.log('could not get user', message.split('\n')[0])
    const user = await getSecuredUser(uid, loadOrg)
    return user
  }
}

export const handleIfPromo = async ({uid, oid}) => {
  const user = await getUser(uid, true)  
  const coupon = user.coupon || user.organization.coupon

  console.log({uid, oid, user, coupon})
  if (coupon) {
    try {
      const {data} = await fbFunc.httpsCallable('handlePromotion')({uid, oid, coupon})
      trackCustom({category: 'User', action: 'Promotion Applied', data: {uid, oid, coupon}})
      return data
    } catch (error) {
      trackCustom({category: 'User', action: 'Promotion Failed', data: {uid, oid, coupon}})
      console.log('Promo could not be applied')
    }
  }
  return false
}

/**
 * Is coupon valid
 * @param coupon code
 * @returns coupon info or 404
 */
export const checkCoupon = async ({code}) => {
  if (code) {
    try {
      const {data} = await fbFunc.httpsCallable('getCoupon')({couponId: code})
      return data
    } catch (error) {
      console.log('Promo could not be applied')
    }
  }
  return false
}

// this may no longer be needed since workspaces/api are added/needed after workspace is created seperatly
const createTempUser = async ({uid, oid, locale}) => {
  const t = new Date().getTime()
  const session = uuidv4()
  const id = CryptoJS.AES.encrypt(`${uid}--${t}--${session}`, CRYPTO_KEY).toString()
  const body = {
    t: id.slice(20, 30),
    f: id,
    g: `${t}${id}`,
    id: uuidv4(),
    o: oid,
    system: uuidv4(),
    locale
  }
  const base  = `${getApiUrl(locale)}/user` // 'http://localhost:3000/user/dev'
  const uri = `${base}/ct/${uid}/?id=${uuidv4()}&s=${uuidv4()}&t=${t}`
  console.log({body, uri})
  const response = await axios.post(uri, body)
  console.log(response)
  if (response.status === 200) return session
  else return 
    
}

// Creates a new API user object in knch.io
// for enchaced security, this uses a one-time-use firebase auth token to call a firebase function
// which then uses a private key to call knch.io private endpoint
const createNewApiUser = async (user) => {
  console.log('init user for api', user)
  if (!user) return null
  console.log('creating user api', user)
  const session = await createTempUser(user)
  console.log('session received', session)
  return {session}
}

export const isCorrector = async (userId) => {
  const {isCorrector} = (await db.collection('Users').doc(userId).get()).data()
  return !!isCorrector
}

export const genNewApiToken = async (label) => {
  const response = await fbFunc.httpsCallable('genAuthToken')({label})
  console.log('response', response)
  return response
}

export const removeApiToken = async (label) => {
  if (!label) return console.log('no label to remove')
  const response = await fbFunc.httpsCallable('genAuthToken')({label: encodeURI(label), remove: true})
  console.log('response', response)
  return response
}

// Generates a new token for a user for a session
// for enchaced security, this uses a one-time-use firebase auth token to call a firebase function
// which then uses a private key to call knch.io private endpoint
export const getNewUserToken = async () => {
  let {uid, locale} = firebaseAuth().currentUser || {}
  if (!uid) {
    console.error('No user found.')
    return null
  }
  try {
    const {organization: oid} = (await db.collection('Users').doc(uid).get()).data() || {}
    if (!oid) {
      console.error('No organization found.')
      return null
    }
    const {locale: orgLocale = 'us'} = (await db.collection('Organizations').doc(oid).get()).data() || {}  
    locale = orgLocale
  } catch ({message}) {
    console.log('Could not get user information due to perms. Trying via API request.')
    const {organization} = await getUser(uid, true) 
    console.log(organization)
    locale = organization?.locale
  }

  const {data} = await fbFunc.httpsCallable('genAuthToken')({locale})
  console.log('Auth Token', data)
  setAuthTokenCookie(data.id, locale)
  return data
}
// TODO: how to pause if no token and wait for token before future calls
// export const getNewUserToken = _debounce(_getNewUserToken, 2000)

export const getStripePortalSession = async (oid) => {
  try {
    const {data: {url}} = await fbFunc.httpsCallable('createStripeUserPortalSession')({oid, url: window.location.href})
    window.location.href = url
  } catch (error) {
    console.log('Could not get user', oid, error)
    return error
  }
}

// re-pull user information from admin Firebase auth
export const fixMissingDBUser = async (uid) => {
  try {
    const response = await fbFunc.httpsCallable('fixNoDBUser')({uid})
    console.log('fixMissingDBUser', response)
    const url = response?.data?.url
    if (url) {
      console.log('** redirecting fixMissingDBUser', url)
      window.location.href = url
    }
  } catch (error) {
    console.log('Could not get user', uid, error)
    return error
  }
}

export const customToken = async (uid) => {
  if (!uid || !hasAAK) return 
  try {
    const response = await fbFunc.httpsCallable('customToken')({uid, hasAAK})
    console.log('customToken', response)
    return response?.data
  } catch (error) {
    console.log('Could not get user token', uid, error)
    return error
  }
}

export const checkNonFBSAML = async (uid) => {
  try {
    const response = await fbFunc.httpsCallable('saml')()
    console.log('customToken', response)
    return response?.data
  } catch (error) {
    console.log('Could not get user token', uid, error)
    return error
  }
}

const initUserForAuthUser = user => {
  // console.log('initUserForAuthUser', user.organization, user.organizations)
  return {
    uid: user.uid,
    email: user.email,
    name: user.name || user.displayName || '',
    avatar: user.photoURL,
    provider: user.provider,
    coupon: 'mHkcB49b', // 5 mins free by default
    settings: {
      emailMeOnComplete: true,
      autoComments: true,
      autoToolbar: true,
      videoSize: 'large',
      showWelcome: true,
      dashFilter: 'My Collections',
      showSpeakers: true,
      autoStop: false,
      autoScroll: true,
      showReview: true
    }
  }
}

export const getInvites = async (oid) => {
  try {
    const {data} = await fbFunc.httpsCallable('getOrgInvitees')({oid})
    console.log('inviteresult', data)
    return data
  } catch (error) {
    console.log('error inviting', error)
    return []
  }
}

export const getUserInvites = async (user) => {
  try {
    // console.log('settings useSettings getInvites')
    const results = await fbFunc.httpsCallable('getUsersInvites')()
    return results
  } catch (error) {
    console.log('error inviting', error)
    return []
  }
}

export const isSafeEmailForEnv = (email) => {
  // console.log('email', email)
  if (email === undefined) {
    // console.log('isSafeEmailForEnv', email)
    return true
  }

  if (isDev() && !window.location.host.includes('localhost')) {
    // console.log('domain', domain)
    const match = email.split('@').slice(1).join('').match(/^(konch\.ai|ops\.getwingapp\.com|gitstart\.(dev|com))$/i) || email === 'sergeypopenko@gmail.com'
    if (!match) {
    // console.log('Not Allowed')
      window.location.href = '/not_allowed'
      return false
    }
  }
  return true
}

const updateApiTokenCookie = async (user) => {
  console.log('auth updateApiTokenCookie', user, '')
  if (!getAuthTokenCookie(user.locale)) {
    console.log('auth updateApiTokenCookie no cookie', user.uid)
    const data = await getNewUserToken()
    if (data && data.id) {
      setAuthTokenCookie(data.id)
      return user
    }
    return new Error('Could not get token')
  } else return user
}

export const addUserToExistingOrg = ({uid, oid: organization, email}) => {
  const emailIndex = getEmailKey(email) 
  console.log('removing invite for', emailIndex, 'and add auto user to org', organization)
  const calls = [
    // add the user to the org
    db.collection('Users')
      .doc(uid)
      .update({organization, [`organizations.${organization}`]: true})
      .catch(err => console.log('Could not add user to org', organization, err))
  ]
  // remove the invite
  if (emailIndex) {
    calls.push(
      db.collection('Organizations')
        .doc(organization)
        .update({[`invites.${emailIndex}`]: FieldValue.delete()})
        .catch(err => console.log('Could not clean out invite user from org', emailIndex, err))
    )
  }
    
  return Promise.all(calls)
}

// AKA auto accept invite
export const addEnterpriseOrgByDomain = async (user) => {
  const {email, uid} = user
  if (!email) return user
  let [domain] = email.split('@').reverse()
  domain = domain.toLowerCase()
  let oid // org id

  // DEIC
  if (domain.match(/(^|\.)(deic|dtu|au)\.dk$/)) oid = '-deicunikeyfororg09252018'
  if (domain.match(/(^|\.)cbs\.dk$/)) oid = '-cbs12981hdnc-ou8eoiu'
  if (domain.match(/(^|\.)aau\.dk$/)) oid = '-aau7g982022nc-ou9fUgk'

  // Konch
  else if (domain.match(/^konch\.ai$/) && email.includes('autojoin')) oid = isProd() ? '-konchtestorganization' : '-konchtest'
  
  // if an auto-join org was found
  if (oid) {
    console.log('auto joining', oid)
    await addUserToExistingOrg({uid, oid, email})
  } else {
    console.log('not auto joining any orgs')
    return
  }
  return oid
}

const saveNewUserToFB = async (user) => {
  const {code} = getLocalPromotionCodes(true) || {}
  const now = new Date().getTime()
  user.creationTime = now
  user.lastSignInTime = now
  if (code) user.coupon = code
  try {
    console.log('attempting to save new user to FB')
    await db.collection('Users').doc(user.uid).set(user)
  } catch (error) {
    console.log('no perms to add user so makiing remote call. Fix this.', error)
    await setUserFBFunc(user)
  }
  return user
}

// get a user that is not easily fetched with DB rules
export const setUserFBFunc = async (user) => {
  try {
    const response = await fbFunc.httpsCallable('setUserFBFunc')({user})
    console.log('setUserFBFunc', {response})
    return response
  } catch ({code, message, details}) {
    console.log('Issue updating', code, message, details)
    return {error: code}
  }
}

/*
* Params: Firebase Auth User Object
* returns: a Firebase DB User object w/ apiKey
*
* 1. Check if there is alreadty a user object in DB
* 2. If there is, return the user object
* 3. If there isn't...
*   3.a. create a new user object
*   3.b. Create an API key and save as cookie
*   4.c. return the user
*/
export const addUserIfDoesNotExist = (user, oid, provider) => {
  const sfDocRef = db.collection('Users').doc(user.uid)
  trackIdentity({uid: user.uid})

  return db.runTransaction((transaction) => {
    // This code may get re-run multiple times if there are conflicts.
    return transaction.get(sfDocRef).then(async (sfDoc) => {
      if (!sfDoc.exists) {
        console.log('auth does not exists.', user)
        return initUserForAuthUser(user)
      } else {
        const user = {...sfDoc.data(), uid: sfDoc.id}
        await updateApiTokenCookie(user)
      }
    })
  }).then(async (userObj) => {
    if (!userObj) {
      console.log('Skipping reg of user...', userObj)
      return
    }
    console.log('addUser Transaction successfully committed!')
    // get IP to determine locale
    const ipInfo = await axios.get('https://api.ipapi.com/api/check?access_key=60336f0be5a1d041ac82ff4c9d9b31c5')
    console.log({ipInfo})
    const countryCode = (ipInfo.data || {}).country_code

    // create API user, org and tokens
    try {
      // coupon added to user MUST come before
      // creation of API user to catch free min accounts
      console.log('addUser Adding to Firestore')
      userObj.locale = countryCode && EU.indexOf(countryCode) >= 0 ? 'eu' : 'us'
      userObj.countryCode = countryCode
      // if (provider) userObj.provider = user.provider
      userObj = await saveNewUserToFB(userObj)
      const {uid, email} = userObj

      console.log('addUser Add to organizations')
      let orgId = oid
      if (orgId) await addUserToExistingOrg({uid, email, oid})
      else orgId = await addEnterpriseOrgByDomain(user) 

      let create
      // if this is an enterpise user based on domain email, or SAML/SSO than auto add them to workspace
      if (!orgId) {
        console.log('addUser Creating Org')
        orgId = uuidv4()
        create = createOrganization({...userObj, orgId})
      }
      
      // create API tokens for user and org
      const {session, error} = await createNewApiUser({...userObj, oid: orgId})
      console.log('addUser initializing session cookie', session, error)
      setAuthTokenCookie(session)
      
      if (error) {
        console.log('addUser reg error', error)
        Sentry.captureMessage(`createNewAPIUser: could not handle add user ${userObj.uid} ${userObj.locale} if not exists for  ${error}`)
        return {...userObj, error}
      }

      // update email on account for SSO thatis not part of FB suite
      if (email && provider && provider !== 'google') {
        console.log('adding email to sso', provider)
        await updateEmail(email) 
      }

      console.log('addUser completed')
      // track user reg
      // trackCustom({category: 'User', action: 'Created', data: userObj})
      await create // once everthing else is done wait for create to finish
      return userObj
    } catch (err) {
      trackCustom({category: 'User', action: 'Creation Error', data: userObj})
      console.error('addUser could not handle add user if not exists', err)
      Sentry.captureMessage('could not handle add user if not exists')
      Sentry.captureException(err)
      return err
    }
  }).catch((error) => {
    console.log('Transaction failed: ', error)
    return error
  })
}

const getLocalPromotionCodes = (clear) => {
  const codes = window.localStorage.getItem('codes')
  if (codes) {
    if (clear) window.localStorage.removeItem('codes')
    return JSON.parse(codes)
  }
  return null
}

export const fetchUrlForPromotion = () => {
  const {location: {search}} = window
  const {coupon, promo} = qs.parse(search.substr(1))
  const code = coupon || promo
  console.log({coupon, promo})
  if (promo) trackCustom({category: 'User', action: 'Promotion Requested', data: {coupon, promo}})
  if (coupon) trackCustom({category: 'User', action: 'Coupon Requested', data: {coupon, promo}})
  if (code) {
    console.log({code})
    window.localStorage.setItem('codes', JSON.stringify({coupon, promo, code}))
  }
  return {code, coupon, promo}
}

export const PopUpAuth = async (providerString = 'google', organization) => {
  trackCustom({category: 'User', action: 'Login Via SSO', data: {organization, providerString}})
  
  const provider = providerString === 'google' ? GoogleProvider : new firebase.auth.SAMLAuthProvider(providerString)
  console.log('SAML AUTH PROVIDER', provider)
    
  const result = await firebase.auth().signInWithPopup(provider)
  console.log('SAML RESULT', result)

  const {user, additionalUserInfo = {}} = result
  const {profile = {}, providerId} = additionalUserInfo || {}
  const {providerUID} = (user.providerData || [])[0] || {}

  // make user object editable
  const jsonUser = user.toJSON()
  let u = {...jsonUser, provider: profile} 

  // if user is wayf and dupe then use custom auth
  if (providerString !== 'google') {
    // WAYF
    if (profile.mail) u.email = profile.mail
    if (profile.displayName) u.displayName = profile.displayName

    try {
      const result = await checkNonFBSAML(user)
      console.log('SAML Remote RESULT', result)
      if (result?.token) {
        const user = await firebaseAuth().signInWithCustomToken(result?.token)
        u = {...user.toJSON(), provider: profile} 
      }
      console.log('SAML checkNonFBSAML', result)  
      
    } catch (error) {
      console.log('SAML NO checkNonFBSAML', error)  
    }  
  }
  
  console.log('SAML AGGREGATE', {profile, organization, providerUID, providerId})

  if (!isSafeEmailForEnv(u.email)) {
    const message = 'SAML User is not allowed on DEV Domain'
    console.log(message)
    return new Error(message)
  }

  try {
    console.log('SAML Final User', u)    
    const data = await addUserIfDoesNotExist(u, organization, providerString)
    console.log('SAML Login Completed', data)
    await stampLoginTime(user)
    return data
  } catch (err) {
    console.error(err, '### SAML could not add user ')
    Sentry.captureMessage('SAML Issues with popup auth', err, result)
    Sentry.captureException(err)
    return new Error('Could not get token for user')
  }
}

export const emailTranscriptMembers = async ({userIds, url}) => {
  await fbFunc.httpsCallable('emailTranscriptMembers')({userIds, url})
}

export const getRepliesWithUserInfo = async (_replies, content, tags, uid, avatar, created) => {
  const {data} = await fbFunc.httpsCallable('getRepliesWithUserInfo')({_replies, content, tags, uid, avatar, created})
  return data
}

export const adminImpersonateLogin = async (key) => {
  try {
    console.log('Impersonate')
    // remove admin token and logout
    removeAuthTokenCookie()
    await firebaseAuth().signOut()
    // login as
    const user = await firebaseAuth().signInWithCustomToken(key)
    console.log('User', user)
    await updateApiTokenCookie(user)
    console.log('Found user - sending to dashboard.', user.email, user.uid, user)
    return true
  } catch (error) {
    console.log('error', error)
    return false
  }
}

export const logout = (redirect = true) => {
  removeAuthTokenCookie()
  firebaseAuth()
    .signOut()
    .then(result => {
      if (redirect) window.location.href = '/signin'
    })
}

export const getProfileSetting = async (profile) => {
  const {settings} = (await db.collection('Users').doc(profile.uid).get()).data()
  return settings
}

export const updateUserName = async (uid, name) => {
  await db.collection('Users').doc(uid).update({name})
}

export const updateOrgName = async (oid, name) => {
  await db.collection('Organizations').doc(oid).update({name})
}

export const createOrganization = async ({uid, name, email, locale, orgId}) => {
  console.log('CREATING ORG')
  const oid = orgId || uuidv4()
  const owners = {[uid]: true}
  const organization = {
    owners, 
    users: owners, 
    name: name || email.split('@')[0], 
    creationTime: new Date().getTime()
  }
  if (locale) organization.locale = locale

  // init organization
  await Promise.all([
    db.collection('Organizations').doc(oid).set(organization),
    db.collection('Users').doc(uid).update({organization: oid, [`organizations.${oid}`]: true})
  ])

  // Apply coupons or promotions to the org
  try {
    const result = await handleIfPromo({uid, oid})
    console.log('Promo result', result)
  } catch ({message}) {
    console.log('Promo issue', message)
  }
  
  return oid
}

export const markOrganizationForDeletion = async (oid) => {
  const response = await fbFunc.httpsCallable('markOrganizationForDeletion')({oid})
  return response
}

export const addUserCoupon = async (profile, coupon) => {
  if (profile.uid && coupon) {
    await db.collection('Users').doc(profile.uid).update({coupon})
  }
}

export default firebase
