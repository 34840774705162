import React, {useState, useEffect} from 'react'
import FadeIn from 'react-fade-in'
import {Card, Heading, ContentWrapper, SubHeading, TitleText} from '../Settings/StyledComponent'
import styled from 'styled-components'
import Chip from '@material-ui/core/Chip'
import TextField from '@material-ui/core/TextField'
import PromoIcon from '@material-ui/icons/Loyalty'
import useProfile from 'hooks/useProfile'
import useOrganization from 'hooks/useOrganization'
import Button from 'components/common/Button'
import Spinner from 'components/common/Spinner'
import Invites from 'components/common/Settings/Invites'
import {fixMissingDBUser, checkCoupon} from 'utils/Firebase'
import * as Sentry from '@sentry/react'

const PageWrapper = styled.div`
  z-index: 50;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgb(236, 240, 244,.9);
  display: flex;
  align-items: start;
  justify-content: center;
  padding-top: 10vh;
`

export default () => {
  const {uid, oid, organizations, profile, switchOrganization} = useProfile() // syncProfileOrganizations
  const {isLoaded, coupon, locale} = profile
  const {create} = useOrganization({uid})
  const [name, setName] = useState('')
  const [processing, setProcessing] = useState(false)
  const [couponData, setCouponData] = useState()
  const [loadingCoupon, setLoadingCoupon] = useState()

  const profileOrgs = profile.organizations || {}
  const profileOrgCount =  Object.keys(profileOrgs).length
  const hasProfileOrgs = profileOrgCount > 0

  // const minutesInSeconds = 60
  // const secondsinMilli = minutesInSeconds * 1000
  // const now = new Date().getTime()
  // const itsBeenAMinuteSinceJoining = now - profile.creationTime > secondsinMilli

  const createWorkspace = async () => {
    setProcessing(true)
    await create({uid, name, locale})
    setProcessing(false)
  }

  useEffect(() => {
    // console.log({organizations})
    // console.log({isLoaded, profileOrgs, organizations})
    if (isLoaded && organizations.length > 0 && !oid) { 
      console.log('syncing', uid)
      // syncProfileOrganizations()
      switchOrganization(organizations[0].uid)
    } else if (isLoaded && !profile.email && !(profile.providerId || profile.provider)) {
      console.log('we have a problem')
      Sentry.captureMessage(`User was missing data in DB ${uid}`)
      fixMissingDBUser(uid)
    }
  }, [isLoaded, organizations.length])

  const getCouponInfo = async (coupon) => {
    setLoadingCoupon(true)
    const data = await checkCoupon({code: coupon})
    if (data.valid) setCouponData(data)
    else setCouponData(null)
    setLoadingCoupon(false)
  }

  useEffect(() => {
    if (coupon) getCouponInfo(coupon)
  }, [coupon])

  // console.log({isLoaded, oid, profileOrgCount, hasProfileOrgs, orgs: profile.organizations})

  if (!isLoaded || (isLoaded && oid && hasProfileOrgs)) return ''
  // if (!itsBeenAMinuteSinceJoining) return ''

  if (!window.navigator.onLine) return ''
    
  return (
    <PageWrapper>
      <FadeIn>
        <ContentWrapper className='create-workspace'>
          <Heading>Create a Workspace</Heading>
          <SubHeading>Create a workspace to organize your transcriptions.</SubHeading>
          <Card style={{minHeight: 'initial'}}>
            <form  
              noValidate 
              autoComplete='off' 
              onSubmit={evt => {
                createWorkspace()
                evt.preventDefault()
              }}>
              <p/>
              <TextField label="Workspace Name" fullWidth variant="outlined" onChange={evt => setName(evt.target.value)} />
              <div style={{margin: '20px 0',display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                {
                  loadingCoupon &&  <Spinner small />
                }
                {
                  couponData && (
                    <Chip
                      style={{    
                        backgroundColor: 'transparent',
                        marginLeft: '12px',
                        borderRadius: '11px',
                        borderColor: '#DDD'
                      }}
                      avatar={<PromoIcon/>}
                      label={`Includes ${couponData.name}`}
                      variant="outlined"
                    />
                  )
                }
                <Button   
                  type='button' 
                  disabled={!name || processing} 
                  onClick={createWorkspace} 
                  raised 
                  primary
                  style={{    
                    marginLeft: '12px',
                  }}
                >
                  {
                    processing ?
                      <Spinner small color='#FFF' /> :
                      'Create Workspace'
                  }
                </Button>
              </div>
            </form>
            <div style={{opacity: .8, marginBottom: '20px'}}>
              <p/>
              <Invites 
                noHead 
                noMessageOnEmpty 
                hideDecline 
                header={<TitleText>Or, accept an invite to an exiting Workspace</TitleText>} 
              />
            </div>
          </Card>
        </ContentWrapper>
      </FadeIn>
    </PageWrapper>
  )
}
