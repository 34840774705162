import React, {useState, useEffect} from 'react'
import styled from 'styled-components'

import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'

import Button from 'components/common/Button'
import Input from 'components/common/Input'
import SelectLanguage, {isPrecisionEnabled} from './SelectLanguage'
import SelectDictionary from './SelectDictionary'
import useUpload from 'hooks/useUpload'
import useProfile from 'hooks/useProfile'
import useDictionary from 'hooks/useDictionary'
import useKaltura from 'hooks/useKaltura'
import usePlan from 'hooks/usePlan'
// import useGoogleDrivePicker from 'hooks/useGoogleDrivePicker'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
// import {faGoogleDrive} from '@fortawesome/free-brands-svg-icons'
import useChooser from 'hooks/useDropboxChooser'
import {faDropbox} from '@fortawesome/free-brands-svg-icons'

const Flex = styled.div`
  display: flex;
  flex-direction: ${({direction}) => direction ? direction : 'row'};
`

const Form = styled.div`
  max-width: 370px;
  width: 100%;
  ${props => !props.isUrlForm && 'border-left: 1px solid #000;'}
  padding-left: 40px;
`

const FormTitle = styled.div`
  font-weight: 500;
  font-size: 24px;
  color: #FFFFFF;
  letter-spacing: 0;
  line-height: 44px;
`

const Root = styled.div`
  margin-bottom: 20px;
  .MuiFormLabel-root {
    color: #FFF;
  }
  .MuiFormLabel-root.Mui-focused  {
    color: #888;
  }
  .MuiSelect-nativeInput {
    color: #FFF;
  }
  .MuiInputBase-root {
   color: #FFF; 
   font-weight: bold;
  }
`

const InputErrorMsg = styled.div`
  color: red;
  font-size: 12px;
  margin-bottom: 2rem;
`

// const GoogleDriveButton = styled.button`
//   width: 100%;
//   padding: .7rem 0;
//   margin: 1rem 0;
//   font-size: 16px;
//   cursor: pointer;
// `
const DropboxButton = styled.button`
  width: 100%;
  padding: .7rem 0;
  margin: 1rem 0;
  font-size: 16px;
  cursor: pointer;
`

const Kaltura = ({url: initUrl, onChange}) => {
  const {list, media = [], kalturaAppKey} = useKaltura()
  const [url, setUrl] = useState(initUrl || '')

  React.useEffect(() => {
    kalturaAppKey && list()
  }, [kalturaAppKey])

  return ( 
    <Root>
      <FormControl variant="filled" fullWidth>
        <InputLabel id="select-media-from-integration-label">
          {
            url ? 'Selected media' : 'Select media from Kaltura'
          }
        </InputLabel>
        <Select
          labelId="select-media-from-integration-label"
          id="select-media-from-integration"
          value={url}
          onChange={(evt, {props: {children: name}}) => {
            const url = evt.target.value
            setUrl(url)
            onChange({name, url})
          }}
        >
          {media.map((m, i) => (
            <MenuItem key={i} value={m.url}>
              {m.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Root>
  )
}

export default ({isUrlForm, isLinkUpload, setUrlValidityStatus}) => {
  const {uid, oid} = useProfile()
  const {usage = {}} = usePlan({oid, uid})
  const {isOpen, editingUpload, defaultLanguage, setDefaultLanguage, update, checkUrl, closeDialog, updateDictionary, transcribe} = useUpload()
  const {name: initName, id} = editingUpload || {}
  const [name, setName] = useState(initName)
  const [languageCode, setLanguage] = useState(defaultLanguage)
  const {dictionaries} = useDictionary({uid, languageFilter: languageCode || defaultLanguage})

  const [url, setUrl] = useState('')
  const [dictionary, setDictionary] = useState('')
  const [urlErrorMessage, setUrlErrorMessage] = useState('')
  const [isValidUrl, setIsValidUrl] = useState(false)
  // const {open:openGoogleDrivePicker, data, authRes} = useGoogleDrivePicker()
  const {open, isOpen: isDropboxOpen} = useChooser()

  // if workspace has precision minutes, let them request it
  const allowPrecision = usage.hasPrecision
  const showPrecision = false && allowPrecision && isPrecisionEnabled({languageCode})

  // useEffect(() => {
  //   if(data){
  //     if(data.docs.length < 1) return
  //     const doc = data.docs[0]
  //     console.log(authRes)
  //     // const {name, id, url} = doc
  //     // const accessToken = authRes.access_token
  //     const {name, id} = doc

  //     // setName(name)
  //     // setUrl(url)
  //     // handleCheckUrl(`https://www.googleapis.com/drive/v3/files/${id}?alt=media`, {'Authorization': `Bearer ${accessToken}`})
  //     // handleCheckUrl(url)

  //     // get the webContentLink using the ID.
  //     window.gapi.client.drive.files
  //       .get({fileId: id, fields: '*'})
  //       .then((res) => {
  //         const webContentLink = JSON.parse(res.body).webContentLink
  //         setName(name)
  //         setUrl(webContentLink)
  //         handleCheckUrl(webContentLink)
  //       })
  //       .catch((err) => {
  //         console.log('is error', err)
  //       })
  //   }
  // },[data])

  useEffect(() => {
    setName(initName)
  }, [initName])

  useEffect(() => {
    !isUrlForm && !isLinkUpload && updateDictionary({id, dictionary})
  }, [dictionary])

  const handleLanguage = (lang) => {
    if (languageCode !== lang) {
      setDictionary(undefined)
      setLanguage(lang)
    }
  }

  const handleCheckUrl = async (link) => {
    if(!link) {
      setUrlValidityStatus({
        loadingStatus: false,
        urlValidity: false
      })
      return
    }

    setUrlValidityStatus({
      loadingStatus: true,
      urlValidity: false
    })
    setUrlErrorMessage('')
    const {payload} = await checkUrl({url: link})

    if (payload.error) {
      setUrlValidityStatus({
        loadingStatus: false,
        urlValidity: false
      })
      setIsValidUrl(false)
      setUrlErrorMessage(payload.error)
    } else {
      setUrlValidityStatus({
        loadingStatus: false,
        urlValidity: true
      })
      setUrlErrorMessage('')
      setIsValidUrl(true)
    }
  }

  const onSubmit = (evt, isPrecision) => {
    if (disabled) return
    const beta = evt.shiftKey   
    const params = {dictionary, languageCode, url, name, id, isPrecision, beta, isLinkUpload}
    if (isUrlForm || isLinkUpload) transcribe(params)
    else update(params)
    closeDialog()
  }

  const disabled = !name || !languageCode || (isLinkUpload && !isValidUrl)

  return (
    <Form isUrlForm={isUrlForm}>
      {
        isUrlForm ? (
          <Kaltura
            onChange={({name, url}) => {
              setName(name)
              setUrl(url)}
            }
          />
            
        ) : (
          <>
            <FormTitle>
              Extra details?
            </FormTitle>
            
            {/*
              isOpen === 'google-drive' && (
              <GoogleDriveButton onClick={() => {
                openGoogleDrivePicker()
              }}><FontAwesomeIcon icon={faGoogleDrive} /> Select from Google Drive</GoogleDriveButton>)
            */}
            {isOpen === 'dropbox' && (
              <DropboxButton onClick={async () => {
                const [file] = await open()
                setName(file.name)
                setUrl(file.link)
                handleCheckUrl(file.link)
              }} disabled={isDropboxOpen}><FontAwesomeIcon icon={faDropbox} /> Select from Dropbox</DropboxButton>
            )}
            <Input
              value={name || ''}
              id='uploader-name-input'
              light
              onChange={(evt) => {
                setName(evt.target.value)
              }}
              placeholder='Give your transcript a name'
            />
            <>
              {isOpen === 'link' && (
                <Input
                  value={url || ''}
                  id='uploader-url-input'
                  light
                  onBlur={(evt) => handleCheckUrl(evt.target.value)}
                  onChange={(evt) => {
                    setUrl(evt.target.value)
                  }}
                  placeholder='Enter a URL'
                />
              )}
              {urlErrorMessage && <InputErrorMsg>{urlErrorMessage}</InputErrorMsg>}
            </>
          </>
        )
      }
      
      <SelectLanguage
        isLoaded
        languageCode={languageCode}
        onSelectLanguage={handleLanguage}
        onSetLanguageDefault={setDefaultLanguage}
        defaultLanguage={defaultLanguage}
      />

      { 
        dictionaries && dictionaries.length > 0 &&
        <SelectDictionary
          onChange={(dictionary) => setDictionary(dictionary)}
          languageCode={languageCode}
          dictionaries={dictionaries}
        />
      }

      <Flex direction='column'>
        <Button
          accent
          raised
          fullWidth
          medium
          onClick={evt => onSubmit(evt, false)}
          disabled={disabled}
        >
          Start Transcribing
        </Button>

        {
          showPrecision && (
            <p>
              <Button
                accent
                raised
                fullWidth
                medium
                primary
                onClick={evt => onSubmit(evt, true)}
                disabled={disabled}
              >
                Request Precision
              </Button>
            </p>
          )
        }
        
      </Flex>
    </Form>
  )
}
