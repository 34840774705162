import client from 'api/client'

export const addHighlight = ({collectionId, highlightId, data}) => client().post(
  `/collections/v2/${collectionId}/highlights/${highlightId}`,
  data
)

export const updateHighlight = ({collectionId, highlightId, data}) => client().put(
  `/collections/v2/${collectionId}/highlights/${highlightId}`,
  data
)

export const removeHighlight = ({collectionId, highlightId}) => client().delete(
  `/collections/v2/${collectionId}/highlights/${highlightId}`
)

export const fetchHighlights = ({collectionId, locale}) =>
  client().get(`/collections/v2/${collectionId}/highlights`, {locale})

export const updateReply = ({collectionId, highlightId, data, replyId}) => client().post(
  `/collections/v2/${collectionId}/highlights/${highlightId}/replies/${replyId >= 0 ? replyId : ''}`,
  data
)
