import React from 'react'
import cx from 'classnames'

const Word = React.memo(({isFirst, wordObject}) => {
  const {
    bold: boldStr,
    bookmark,
    end_time: endTime,
    start_time: startTime,
    strike: strikeStr,
    italic: italicStr,
    type,
    cutoff,
    skip,
    alternatives: [{content, confidence}]
  } = wordObject

  // pronunciatin or content with more than just punctuation
  const isPronunciation = type === 'pronunciation' || !!content.match(/[^\p{P}]/gu)
  
  const bold = boldStr === 'true' || boldStr === true || undefined
  const strike = strikeStr === 'true' || strikeStr === true || undefined
  const italic = italicStr === 'true' || italicStr === true || undefined
  
  const classes = {
    bold,
    strike,
    italic,
    bookmark: !!bookmark,
    'low-confidence': parseFloat(confidence) < .84 ,
    pronunciation: isPronunciation,
    cutoff
  }

  if (isNaN(startTime) || isNaN(endTime)) console.log('word does not have a start or end time', startTime, endTime)

  return (
    <>
      {isPronunciation && !isFirst && (
        <span
          className={cx('space', {bookmark: !!bookmark})}
        >
          {' '}
        </span>
      )}

      <span
        className={cx('word', classes)}
        data-bookmark={bookmark}
        data-start_time={startTime}
        data-end_time={endTime}
        data-skip={skip}
        data-type={type}
        data-confidence={confidence}
        data-bold={bold}
        data-strike={strike}
        data-italic={italic}
      >
        {content}
      </span>
    </>
  )
})

export default Word
