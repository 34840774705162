import {useSelector} from 'react-redux'
import {useFirestoreConnect, useFirestore} from 'react-redux-firebase'
import {
  selectProfile,
  selectSettings,
  getEmailKey,
  selectOrganizations,
  selectOrganization,
  selectInvitedOrganizations
} from 'features/profile'

import {
  addWelcomeCollection as addWelcomeCollectionApi
} from 'api/user'

import {updateEmail as updateEmailAction} from 'utils/Firebase'

export default () => {
  const firestore = useFirestore()

  const profile = useSelector(state => selectProfile(state)) || {}
  const {organization: oid, uid, email} = profile || {}
  const settings = useSelector(state => selectSettings(state, uid)) || {}

  // get orgs for user
  const queryOranizationsWithUser = uid ? {collection: 'Organizations', where: [[`users.${uid}`, '==', true]]} : []
  useFirestoreConnect(queryOranizationsWithUser)
  // get orgs for admins of not users of orgs
  const queryOranizationsWithOwner = uid ? {collection: 'Organizations', where: [[`owners.${uid}`, '==', true]], storeAs: 'OwnerOrganizations'} : []
  useFirestoreConnect(queryOranizationsWithOwner)

  const indexSafeEmail = email ? getEmailKey(email).toLowerCase() : undefined
  const invitedToOrgs = email ? {collection: 'Organizations', where: [[`invites.${indexSafeEmail}`, '!=', '']], storeAs: 'invitedTo'} : []
  useFirestoreConnect(invitedToOrgs)

  const organization = useSelector(state => selectOrganization(state, oid))

  const flagInOrgOwners = (organization.owners || {})[uid]
  const isOwner = flagInOrgOwners === true
  const isAdmin = flagInOrgOwners !== undefined
  profile.isOwner = isOwner
  profile.isAdmin = isAdmin

  const {billing = {}} = organization
  const isEnterprise = billing.enterprise
  const organizations = useSelector(state => selectOrganizations(state, uid)) || []
  const invites = useSelector(state => selectInvitedOrganizations(state, email))
  
  const updateSettings = newSettings => {
    // const newSettings = Object.assign({}, settings, setting)
    const updateSettings = Object.entries(newSettings).reduce((acc, [id, data]) => {
      acc[`settings.${id}`] = data
      return acc
    },{})
    // console.log({updateSettings})
    firestore.collection('Users').doc(uid).update(updateSettings)
  }

  const setDefaultLanguage = (defaultLanguage) => {
    console.log({defaultLanguage})
    updateSettings({defaultLanguage})
  }

  const updateName = name => firestore.collection('Users').doc(uid).update({name})
  const updateAddress = address => firestore.collection('Users').doc(uid).update({address})
  const showWelcome = show => firestore.collection('Users').doc(uid).update({'settings.showWelcome': show})

  const updateEmail = email => updateEmailAction(email)

  const switchOrganization = organization => {
    firestore.collection('Users').doc(uid).update({organization})
    window.Beacon('identify', {oid: organization})
  }

  const syncProfileOrganizations = () => {
    console.log('sync.', uid)
    if (organizations.length === 0) return 
    console.log('syncing..')
    const orgs = organizations.reduce((acc, org) => {
      acc[org.id || org.uid] = true
      return acc
    },{})
    console.log('syncing', orgs)
    return firestore.collection('Users').doc(uid).update({organizations: orgs})
  }

  const declineInvite = (orgId) => {
    firestore.collection('Organizations').doc(orgId).update({
      [`invites.${indexSafeEmail}`]: firestore.FieldValue.delete()
    })
  }

  const acceptInvite = (orgId) => {
    firestore.collection('Organizations').doc(orgId).update({
      [`invites.${indexSafeEmail}`]: firestore.FieldValue.delete(),
      [`users.${uid}`]: true
    })
    firestore.collection('Users').doc(uid).update({
      [`organizations.${orgId}`]: true,
      organization: orgId
    })
    addWelcomeCollectionApi({uid, oid: orgId})
  }

  return {
    acceptInvite,
    billing,
    correctLangCode: (settings?.correctLangCode || 'en').split('-')[0],
    correctLangCodes: settings?.correctLangCodes || ['en'],
    declineInvite,
    email,
    invites,
    isAdmin,
    isOnProbation: profile?.isOnProbation,
    isCorrector: profile?.isCorrector || organization?.isCorrector,
    isEnterprise,
    isKonchEmp: email ? !!(email || '').split('@').slice(1).join('').match(/^(konch\.ai|ops\.getwingapp\.com|gitstart.com)$/i) : false,
    isLoaded: organization?.isLoaded && profile?.isLoaded,
    isOwner,
    oid,
    organization,
    organizations,
    profile,
    setDefaultLanguage,
    settings,
    showWelcome,
    switchOrganization,
    syncProfileOrganizations,
    uid,
    updateAddress,
    updateEmail,
    updateName,
    updateSettings
  }
}
