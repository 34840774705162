import {useDispatch, useSelector} from 'react-redux'
import {useContext} from 'react'
import {
  fetchCollection as fetchCollectionAction,
  fetchCollections as fetchCollectionsAction,
  fetchSubtitles as fetchSubtitlesAction,
  renameCollection as renameCollectionAction,
  deleteCollection as deleteCollectionAction,
  selectCollectionById,
  selectCollectionSharedWith,
  selectCollectionOwner,
  selectCollectionDraftComplete,
  selectCollectionDuration,
  selectCollectionLanguage,
  selectCollectionName,
  selectCollectionSpeakersArray,
  selectCollectionTranscriptionStarted,
  selectCollectionIsPaused,
  selectIsCollectionLoading,
  selectIsCollectionReadOnly,
  selectCollectionStarted,
  selectCollectionRetried,
  selectCollectionVersionId,
  searchCollections as searchCollectionsAction,
  searchLocalCollections as searchLocalCollectionsAction,
  selectSearchededOrgCollections,
  selectCollections,
  selectUserCollections,
  selectCollectionProgress,
  selectSearchedCollections,
  selectSharedCollections,
  selectLockedForEditing,
  selectOrgCollections,
  selectCollectionLogs,
  selectTranscriptCompletionMeta,
  openShareModal as openShareModalAction,
  share as shareAction,
  owner as ownerAction,
  recoverCurrentVersion as recoverCurrentVersionAction,
  cancelRecoverCurrentVersion as cancelRecoverCurrentVersionAction,
  retry as retryAction,
  unPause as unPauseAction,
  checkin as checkinAction,
  addWelcomeCollection as addWelcomeCollectionAction,
  upgrade as upgradeAction
} from 'features/collections'
import {
  selectCollectionSpeakers,
  selectUniqueSpeakerNames,
  selectUniqueSpeakerNameObjects
} from 'features/speakers'
import {selectCollectionHighlights} from 'features/highlights'
import {selectCollectionWords} from 'features/transcripts'
import {selectCollectionPeaks} from 'features/peaks'
import EditorContext from 'context/EditorContext'

const useCollectionV2 = ({id, oid, uid, searchTerm} = {}) => {
  const dispatch = useDispatch()
  const {isCorrector, locale, isReviewer} = useContext(EditorContext)
  const collection = useSelector(state => selectCollectionById(state, id)) || {}
  const duration = useSelector(state => selectCollectionDuration(state, id))
  const draft = useSelector(state => selectCollectionDraftComplete(state, id))
  const isPaused = useSelector(state => selectCollectionIsPaused(state, id))
  const progress = useSelector(state => selectCollectionProgress(state, id))
  const isLoading = useSelector(state => selectIsCollectionLoading(state, id))
  const isReadOnly = useSelector(state => selectIsCollectionReadOnly(state, id))
  const tCompletion = useSelector(state => selectTranscriptCompletionMeta(state, id))
  const isRetried = useSelector(state => selectCollectionRetried(state, id))
  const lockedForEditing = useSelector(state => selectLockedForEditing(state, id, uid))
  const transcriptionStarted = useSelector(state => selectCollectionTranscriptionStarted(state, id))
  const started = useSelector(state => selectCollectionStarted(state, id))
  const isShareModalOpen = useSelector(state => state.collections.shareModal)

  const bookmarks = useSelector(state => selectCollectionHighlights(state, id))
  const language = useSelector(state => selectCollectionLanguage(state, id))
  const name = useSelector(state => selectCollectionName(state, id))
  const speakers = useSelector(state => selectCollectionSpeakers(state, id))
  const speakersArray = useSelector(state => selectCollectionSpeakersArray(state, id))
  const uniqueSpeakerNames = useSelector(state => selectUniqueSpeakerNames(state, id))
  const uniqueSpeakerObjects = useSelector(state => selectUniqueSpeakerNameObjects(state, id))
  const version = useSelector(state => selectCollectionVersionId(state, id))
  const words = useSelector(state => selectCollectionWords(state, id))
  const peaks = useSelector(state => selectCollectionPeaks(state, id))
  const sharedWith = useSelector(state => selectCollectionSharedWith(state, id)) || {}
  const owner = useSelector(state => selectCollectionOwner(state, id)) || {}
  const logs = useSelector(state => selectCollectionLogs(state, id)) || []

  const collections = useSelector(state => selectCollections(state))
  const orgCollections = useSelector(state => selectOrgCollections(state))
  const userCollections = useSelector(state => selectUserCollections(state))
  const sharedCollections = useSelector(state => selectSharedCollections(state))
  const searchedCollections = useSelector(state => selectSearchedCollections(state, searchTerm))
  const searchededOrgCollections = useSelector(state => selectSearchededOrgCollections(state, searchTerm))

  // for search results that don't have draft precision data. TODO: put draft daat in search?
  const isDraftComplete = draft === undefined ? progress >= 50 : draft
  const minTimeToWait = 60 // 1 min
  // minimize the need to ping for updates.....
  const now = new Date().getTime() / 1000
  const sinceStartedSeconds = now - (transcriptionStarted || started)
  // estimated time is 1/2 duration, with 10% padding
  const safeDuration = Math.max(minTimeToWait, duration)
  // half duration is estimate for transcribe, but no less than 30
  const expectedSeconds = Math.max(safeDuration / 2, minTimeToWait)
  const expectedTimeLeft = expectedSeconds - sinceStartedSeconds
  // x times longer than expected to max out wait
  const maxTimeLeft = (safeDuration * 2) - sinceStartedSeconds

  // if it should have been done a while ago, don't ping
  // either there is an issue, or will take a lot of time and user can refresh or return to page later
  const shouldBeDoneButIsNot = !isDraftComplete && maxTimeLeft < 0

  // draft is not complete - tprogress to show if precsiion is used
  const isProcessing = !isDraftComplete && !shouldBeDoneButIsNot  

  const startedString = new Date(started * 1000).toLocaleDateString().replace(/\//g, ' - ')

  let message
  if (isPaused) message = 'Not enough credits or payment issue. See tag for more details.'
  else if (isProcessing && !shouldBeDoneButIsNot)  message = 'Processing...'
  else if (!isProcessing && shouldBeDoneButIsNot && isRetried)  message = 'Could not complete.'
  else if (shouldBeDoneButIsNot)  message = 'Taking longer than expected. Retry?'

  const renameCollection = name =>
    dispatch(renameCollectionAction({id, name}))

  const deleteCollection = name =>
    dispatch(deleteCollectionAction({id}))

  const fetchCollection = versionId =>
    isLoading ? null : dispatch(fetchCollectionAction({id, versionId, locale}))

  const fetchCollections = ({nextPage, orgWide, shared}) =>
    dispatch(fetchCollectionsAction({uid, oid, nextPage, orgWide, shared}))

  const addWelcomeCollection = ({uid, oid}) =>
    dispatch(addWelcomeCollectionAction({uid, oid}))

  const searchCollections = async ({term, nextPage, orgWide}) => {
    return Promise.all([
      dispatch(searchLocalCollectionsAction({oid, term, nextPage, orgWide})),
      dispatch(searchCollectionsAction({oid, term, nextPage, orgWide}))
    ])
  }

  const share = (uid) =>
    dispatch(shareAction({id, uid}))

  const makeOwner = (uid) =>
    dispatch(ownerAction({id, uid}))

  const showShareModal = (on) =>
    dispatch(openShareModalAction({on, id}))

  const fetchSubtitles = (format = 'srt', size = 35) =>
    dispatch(fetchSubtitlesAction({id, format, size}))

  const recoverCurrentVersion = () =>
    dispatch(recoverCurrentVersionAction(id))

  const cancelRecoverCurrentVersion = () =>
    dispatch(cancelRecoverCurrentVersionAction(id))

  const onRetry = async () => {
    await dispatch(retryAction({id}))
    await fetchCollection()
  }

  const onUnPause = async () => {
    await dispatch(unPauseAction({id}))
    await fetchCollection()
  }

  const onCheckin = () =>
    dispatch(checkinAction({id}))

  const onUpgrade = () =>
    dispatch(upgradeAction({id}))

  const shortLanguage = language ? (language || '').split('-')[0] : language
  const pricePerMin = {
    es: 1.4,
    en: 1
  }[shortLanguage]

  const isCorrectorPage = window.location.pathname.indexOf('/corrector') >= 0
  const isReviewPage = window.location.pathname.indexOf('/corrector/us/review/') >= 0
  
  return {
    addWelcomeCollection,
    bookmarks,
    canUpgrade: ['en', 'es'].includes(shortLanguage) && !collection?.demo && tCompletion?.perfection === undefined,
    cancelRecoverCurrentVersion,
    collection,
    collections,
    deleteCollection,
    duration,
    minutes: duration / 60,
    expectedTimeLeft,
    fetchCollection,
    fetchCollections,
    fetchSubtitles,
    isCorrector,
    isDraftComplete,
    isLoading,
    isProcessing,
    isPaused,
    // if it is a corrector, the only reason for read only is if using versions, 
    // otherwise, respect readonly seelctor for user
    isReadOnly: (isCorrectorPage && (isCorrector || isReviewer)) ? !!version : isReadOnly,
    isRetried,
    isShareModalOpen,
    language: (language || '').split('-')[0],
    locale: 'us',
    isCorrectorPage, 
    isReviewPage,
    lockedForEditing: isCorrector && isCorrectorPage ? false : lockedForEditing,
    logs,
    makeOwner,
    message,
    name,
    onCheckin,
    onUpgrade,
    onRetry,
    onUnPause,
    orgCollections,
    owner,
    peaks,
    pricePerMin,
    recoverCurrentVersion,
    renameCollection,
    searchCollections,
    searchedCollections,
    searchededOrgCollections,
    share,
    sharedCollections,
    sharedWith,
    shouldBeDoneButIsNot,
    shortLanguage,
    showShareModal,
    speakers,
    speakersArray,
    startedString,
    transcriptionStarted,
    tCompletion,
    uniqueSpeakerNames,
    uniqueSpeakerObjects,
    userCollections,
    version,
    words
  }
}

export default useCollectionV2
