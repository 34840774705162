import {useMemo, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  fetchUsage as fetchUsageAction,
  selectOrgUsage
} from 'features/usage'
import {fbFunc} from 'utils/Firebase'
import {STRIPE_PK, paygPricePerMin, paygPrice, precisionPrices} from 'utils/env'
import {loadStripe} from '@stripe/stripe-js/pure'
import {toast} from 'components/common/Toaster'
import {fetchCredits as fetchCreditsAction, selectOrgCredits} from 'features/credits'
import {selectOrganization} from 'features/profile'

let stripePromise
const getStripe = () => {
  try {
    if (!stripePromise) {
      stripePromise = loadStripe(STRIPE_PK)
    }
    return stripePromise
  } catch (err) {
    toast.warn('Issues loading payment platform. Please refresh and try again.', {autoClose: 10000, toastId: 'stripe-issue'})
    return
  }
}

export default ({oid, uid, limit = 20}) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [coupon, setCoupon] = useState({})

  const {billing = {}} = useSelector(state => selectOrganization(state, oid))
  const usage = useSelector(state => selectOrgUsage(state, oid)) || {}
  const {credits = null} = useSelector(state => selectOrgCredits(state, oid)) || {}

  const fetchUsage = async () => {
    setLoading(true)
    console.log('fetchUsage', {oid, uid})
    const {payload} = await dispatch(fetchUsageAction({oid, uid}))
    setLoading(false)
    return payload
  }

  const fetchCredits = async () => {
    setLoading(true)
    const {payload} = await dispatch(fetchCreditsAction({oid, customer: billing.customer}))
    setLoading(false)
    return payload
  }

  const getCoupon = async ({isCheckout, coupon: couponId}) => {
    if (!uid || !couponId) return
    try {
      const {data} = await fbFunc.httpsCallable('getCoupon')({couponId, isCheckout})
      // addUserCoupon(profile, data.id)
      setCoupon(data)
      return data
    } catch ({message}) {
      console.log('Not a valid coupon', message)
    }
  }

  const cancelPlan = async () => {
    if (!uid) return
    const params = {uid, oid}
    const {data} = await fbFunc.httpsCallable('cancelSubscription')(params)
    return data
  }

  const handleCheckout = async ({plan, coupon, mode = 'subscription', qty = 1, successRedirect}) => {
    try {
      const params = {
        uid,
        oid,
        plan,
        coupon,
        qty,
        cancelRedirect: window.location.pathname,
        baseUrl: window.location.origin,
        successRedirect,
        mode
      }
      console.log('handleCheckout', params)
      const {data: sessionId} = await fbFunc.httpsCallable('stripeCreateCheckoutSession')(params)
      // When the customer clicks on the button, redirect them to Checkout.
      const stripe = await getStripe()
      const checkout = await stripe.redirectToCheckout({sessionId})
      return checkout
    } catch ({message}) {
      console.error(message)
      return {error: message}
    }
  }

  const getStripePortalUrl = async () => {
    try {
      const {data: {url}} = await fbFunc.httpsCallable('createStripeUserPortalSession')({oid, url: window.location.href})
      window.location.href = url
    } catch (error) {
      console.log('Could not get user', oid, error)
      return error
    }
  }

  const changePlans = async ({plan, coupon, mode = 'subscription', qty = 1}) => {
    // console.log('Update Plan')
    const params = {
      uid,
      oid,
      qty,
      cancelUrl: window.location.href,
      plan
    }
    try {
      const {data} = await fbFunc.httpsCallable('changeStripePlans')(params)
      console.log('Changing...', data)
      if (data.error) {
        if (data.checkout) await handleCheckout({plan, coupon, mode: 'subscription', qty})
        else toast.error('There was an issue processing your request.', {toastId: 'changePlans'})
      }
      await fetchUsage()
      return data
    } catch ({message}) {
      console.error(message)
      return {error: message}
    }
  }
  
  const calcPlanPrecision = useMemo(() => {
    const {plan = {}, usage: currUsage = {}} = usage

    const planDraft = plan.draft >= 999999 ? 'unlimited' : plan.draft
    const planPrecision = plan.precision >= 999999 ? 'unlimited' : plan.precision

    // precision calcs
    let precisionPerc = plan.precision ? (currUsage.precision / plan.precision) * 100 : 0
    let precisionLeft = plan.precision ? plan.precision - currUsage.precision : 0
    if (currUsage.precision > plan.precision) precisionPerc = 100
    if (precisionLeft < 0) precisionLeft = 0

    // draft calcs
    let draftPerc = plan.draft ? (currUsage.draft / plan.draft) * 100 : 0
    if (plan.draft > 9999999 && currUsage.draft > 0) draftPerc = 20
    let draftLeft = plan.draft ? plan.draft - currUsage.draft : 0
    if (currUsage.draft > plan.draft) draftPerc = 100
    if (draftLeft < 0) draftLeft = 0

    return {
      planDraft,
      planPrecision,
      precisionPerc,
      draftPerc,
      draftLeft,
      precisionLeft
    }
  }, [usage])

  const calcForUsageMeter = useMemo(() => {
    const {plan = {}, usage: currUsage = {}} = usage
    const {draft, estimatedDraft} = currUsage || {}
    const {draft: draftPlan} = plan || {}
    const perc = Math.min(isNaN(draftPlan) || 0 ? 0 : (draft / draftPlan) * 100, 100)
    const estPerc = Math.min(isNaN(estimatedDraft) || isNaN(draftPlan) ? 0 : (estimatedDraft / draftPlan) * 100, 100)
    const minutesLeft = Math.max(0, draftPlan - estimatedDraft)
    const paygMins = credits > 0 ? parseInt(credits / paygPricePerMin) : undefined

    return {
      perc,
      estPerc,
      minutesLeft,
      paygPrice,
      paygMins,
      estimatedDraft,
      draftPlan,
    }
  }, [usage])

  return {
    getCoupon,
    cancelPlan,
    getStripePortalUrl,
    handleCheckout,
    usage,
    fetchUsage,
    loading,
    changePlans,
    coupon,
    fetchCredits,
    credits,
    calcPlanPrecision,
    calcForUsageMeter,
    precisionPrices
  }
}
