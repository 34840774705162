import React, {useEffect, useState, useContext} from 'react'
import styled from 'styled-components'
// import {useMediaPredicate} from 'react-media-hook'
import FadeIn from 'react-fade-in'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import moment from 'moment'

import LinearProgress from '@material-ui/core/LinearProgress'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import  MuiButton from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import TocIcon from '@material-ui/icons/Toc'
import AppsIcon from '@material-ui/icons/Apps'
import ArrowDropDownRounded from '@material-ui/icons/ArrowDropDownRounded'

import Spinner from 'components/common/Spinner'
import Button from 'components/common/Button'
import {toast} from 'components/common/Toaster'
import useCollection from 'hooks/useCollection'
import UploadMenu from 'components/common/Popover/UploadMenu'
import usePlan from 'hooks/usePlan'
import CollectionListCard from './CollectionsListCard'
import CollectionsTableView from './CollectionsTableView'
import useProfile from 'hooks/useProfile'
import useUpload from 'hooks/useUpload'
import SearchContext from 'context/SearchContext'
import {DASHBOARD_VIEW} from 'utils/constants'
import Loader from 'components/common/Loaders/Skeleton'
import Loading from 'components/common/Loading'

TimeAgo.addLocale(en)

const Cont = styled.div`
  ${({loading}) => loading && `animation: Pulsate 1s linear infinite;`}
`
const IconContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;
  justify-content: flex-end;
  opacity: .8;
  :hover {
    opacity: 1;
  }
  @media (max-width: 550px) {
     display: none;
  }
`

const ProgressBar = styled.div`
  position: fixed;
  top: 0;
  width: 100vw;
  left: 0;
`

const HeaderCont = styled.div`
  display: inline-flex;
  justify-content: center;
  margin-left: ${({mode}) => mode === DASHBOARD_VIEW.GRID ? '32px' : '-5px' };
  h2 {
    text-transform: uppercase;
    color: #777;
    font-size: .8em;
    @media (max-width: 925px) {
      display: none;
    }
  }
`

const NoMoreMessages = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 24px;
  text-align: center;
  line-height: 44px;
  font-weight: 400;
  margin: 40px auto 100px 40px;
  color: rgba(0,0,0,0.3);
`
const Body = styled.div`
  position: relative;
  min-height: calc(100vh - 81px);
  height: auto;
  margin-bottom: 250px;
  margin: auto;
`

const BigMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 24px;
  text-align: center;
  line-height: 44px;
  font-weight: 400;
  transform: translateY(-230px);
  
  @media (max-width: 500px) {
    line-height: 30px;
    width: 100%;
    margin: auto;
    left: 0;
    top: 0;
    position: relative;
  }
`

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  ${props => props.mode === DASHBOARD_VIEW.LIST ? `
    width: 100%;
    margin-left: 30px;
  ` : `
    margin-left: 15px;
    width: 1071px;
    
    @media (min-width: 1586px) {
      width: 1281px;
    }
    @media (max-width: 1382px) {
      width: 860px;
    }
    @media (max-width: 1080px) {
      width: 654px;
    }
    @media (max-width: 898px) {
      width: 441px;
    }
    @media (max-width: 548px) {
      width: 231px;
    }
  `}  
`

const BigMessageButton = styled(Button)`
  margin-top: 32px;
`
const UploadButton = styled(MuiButton)`
  && {
    background-color: #5173E7;
    color: #FFF;
    padding: 5px 15px 5px 50px;

    :hover {
      background-color: #5173E7;
      opacity: 1;
    }
  }
`
const UploadList = styled.div`
  border-right: solid 2px #4968D0;
  margin: 2px -40px 2px 0;
  padding-top: 4px;
  padding-left: 20px;
  z-index: 1;
  color: #A0B3EF;
  : hover {
    color: #FFF;
  }
`
const ArrowDropDown = styled(ArrowDropDownRounded)`
  font-size: 35px !important;
`

const PopoverPosition = {
  anchorOrigin: {vertical: 'bottom', horizontal: 'center'},
  transformOrigin: {vertical: 'top', horizontal: 'left'},
  margin:'5px 0 30px -21px',
  padding: '15px 30px 15px 30px',
  borderRadius: '10px'
}

const Help = () => <HelpOutlineIcon style={{color: 'rgb(81, 115, 230)', cursor: 'pointer', fontSize: '.7em', margin: '6px'}} onClick={() => [Beacon('article', '605f616124ce107ab4d1a144'), Beacon('open')]} />
const Welcome = (props) => {
  const {openDialog} = useUpload()
  const {updateSettings} = useProfile()
  const {profile} = props

  const {settings} = profile

  const sharedWithView = settings && settings.dashFilter === 'Shared with Me'
  const message = sharedWithView ? <div style={{display: 'flex', alignItems: 'center'}}><div>Nothing is shared with you at this time. </div> <Help /></div> : 'Welcome to Konch, ready to start transcribing?'
  const showButton = sharedWithView ? 'Return to your Collections' : `+ Let's go!`
  const clickAction = sharedWithView ? () => updateSettings({dashFilter: 'My Collections'}) : openDialog
  // chaneg in design to alwasy have at least the add card. 
  if (!sharedWithView) return ''
  return (
    <FadeIn delay={200}>
      <BigMessage>
        {message}
        <div style={{display: 'flex'}}>
          {showButton && <BigMessageButton big raised primary onClick={clickAction}>{showButton}</BigMessageButton>}
        </div>
      </BigMessage>
    </FadeIn>
  )
}

const getFreshViews = () => ({
  'Org Collections': {},
  'Shared with Me': {},
  'My Collections': {}
})

const SearchMessage = ({searchTerm}) => <BigMessage><span>No transcripts matching <b>"{searchTerm}"</b>.<br /> Please try another search.</span></BigMessage>

export default ({render}) => {
  const {searchTerm, loading: searchLoading} = useContext(SearchContext)
  const {profile, uid, oid, settings = {}, organization, updateSettings, showWelcome} = useProfile()
  let {
    dashFilter = settings.dashFilter || 'My Collections',
    dashboardView
  } = settings
  const {fetchCollections, userCollections, orgCollections, sharedCollections, searchedCollections, searchededOrgCollections} = useCollection({uid, oid, searchTerm, searchLoading})
  const [loading, setLoading] = useState(true)
  const {openDialog} = useUpload()
  const {deletedAt} = organization

  const viewOrgWide = dashFilter === 'Org Collections'
  const viewSharedCollections = dashFilter === 'Shared with Me'
  const {usage: currentUsage} = usePlan({oid, uid})

  // reduce reloading already loaded data based on current workspace or views
  // TODO:
  //    This is probably better managed by saving the query in redux
  //    an preventing reload before request based on params.
  const [whatIsLoaded, setWhatIsLoaded] = useState({})
  const loadedOrgInfo = whatIsLoaded[oid] || getFreshViews()
  const loadedView = loadedOrgInfo[dashFilter]
  const {nextPage, loaded} = loadedView || {}
  const loadPage = async (opts) => {
    dashFilter = dashFilter || 'My Collections'
    const {loadNextPage} = opts || {}
    if ((loaded && !nextPage) || (loaded && !loadNextPage)) return null
    setLoading(true)
    const {payload} = await fetchCollections({nextPage, shared: viewSharedCollections, orgWide: !!viewOrgWide})
    loadedOrgInfo[dashFilter].loaded = true
    if ((payload || []).length < 20) delete loadedOrgInfo[dashFilter].nextPage
    else {
      const {id, started, userOrgId} = payload[payload.length - 1] || {}
      loadedOrgInfo[dashFilter].nextPage = {id, started, userOrgId}
      whatIsLoaded[oid] = loadedOrgInfo
      setWhatIsLoaded(whatIsLoaded)
    }

    // if it is a first time reg user hide the welcome interstitial from reg if still showing
    settings?.showWelcome && showWelcome(false)

    // hide loading spinner
    setLoading(false)
  }

  const updateCollections = async (args = {}) => {
    await loadPage(args)
  }

  useEffect(() => {
    if (oid && whatIsLoaded[oid] === undefined) {
      whatIsLoaded[oid] = getFreshViews()
      setWhatIsLoaded(whatIsLoaded)
      updateCollections()
    }

    if(deletedAt) {
      const hours = moment().diff(moment(deletedAt), 'hours')
      const hoursLeft = 48 - parseInt(hours)
      toast.warn(`This Workspace will delete in ${hoursLeft} hour${hoursLeft > 1 ? 's' : ''}`, {autoClose: false, toastId: 'deletion-warning'})
    }
  }, [oid])

  useEffect(() => {
    console.log('Auto', profile.isLoaded, render, oid)
    if (oid && profile.isLoaded) updateCollections()
  }, [dashFilter, render])

  let collections = userCollections
  if (searchTerm && viewOrgWide) collections = searchededOrgCollections
  else if (searchTerm && !viewOrgWide) collections = searchedCollections
  else if (viewSharedCollections) collections = sharedCollections 
  else if (viewOrgWide) collections = orgCollections

  // console.log('asdf', {searchTerm, collections})
  collections = collections.sort((a, b) => b.started - a.started)
  
  const showAddCard = !viewSharedCollections && (!loading || (loading && collections.length !== 0)) && !searchTerm
  let name = organization?.name || ''
  const nameHasWorspace = name.toLowerCase().indexOf('workspace') >= 0 
  const headerName = nameHasWorspace ? name : `${name} Workspace`

  // if there is no default view and it is loaded then use grid. 
  // Also, don't allow table view on very small screens as it is hard to use.
  dashboardView = window.innerWidth < 550 ? DASHBOARD_VIEW.GRID : loading ? dashboardView : dashboardView || DASHBOARD_VIEW.GRID
  // console.log({dashboardView})
  return (
    <Body>
      <div style={{display: 'flex', justifyContent: 'flex-start'}}>
        <Flex mode={dashboardView}>
          <HeaderCont mode={dashboardView}>
            <h2>
              {name ? headerName : <Loader width='200px' height='14px' />}
            </h2>
          </HeaderCont>
     
          <IconContainer style={{marginRight: dashboardView === DASHBOARD_VIEW.LIST ? '25px' : 0}}>
            {showAddCard && dashboardView === DASHBOARD_VIEW.LIST && (
              <>
                <UploadList id='upload-list-btn'>
                  <UploadMenu
                    position={PopoverPosition}
                  >
                    <ArrowDropDown />
                  </UploadMenu>
                </UploadList>
                <UploadButton onClick={() => openDialog({view: 'file-drop'})}>
                  Upload Transcript
                </UploadButton>
              </>
            )}
            {(dashboardView === DASHBOARD_VIEW.GRID) && (
              <IconButton
                onClick={() => updateSettings({dashboardView: DASHBOARD_VIEW.LIST})}
                aria-label="delete"
                size="small"
              >
                <TocIcon fontSize="medium"  />
              </IconButton>
            )}
            {dashboardView === DASHBOARD_VIEW.LIST && (
              <IconButton
                onClick={() => updateSettings({dashboardView: DASHBOARD_VIEW.GRID})}
                aria-label="delete"
                size="small"
              >
                <AppsIcon  fontSize="medium"/>
              </IconButton>
            )}
          </IconContainer>
        </Flex>    
      </div>

      <Cont loading={loading && collections.length === 0 ? 1 : 0}>
        {dashboardView === DASHBOARD_VIEW.GRID ?
          <CollectionListCard 
            isSharedCollections={viewSharedCollections} 
            loading={loading} 
            searchTerm={searchTerm} 
            collections={collections || []}
            usage={currentUsage} /> :
          dashboardView === DASHBOARD_VIEW.LIST ?
            <CollectionsTableView 
              loadPage={loadPage} 
              nextPage={nextPage} 
              isSharedCollections={viewSharedCollections} 
              searchTerm={searchTerm}
              loading={loading} 
              collections={collections || []} 
              usage={currentUsage} /> : 
            <Loading />
        }
      </Cont>
      
      {
        dashboardView === DASHBOARD_VIEW.GRID && nextPage && (searchTerm === '' || searchTerm === null) &&
          <NoMoreMessages>
            <Button onClick={() => loadPage({loadNextPage: true})} disabled={loading} raised primary style={{minWidth: '200px'}}>
              {loading ? <Spinner small center color={'white'} /> : <span>Load More</span>}
            </Button>
          </NoMoreMessages>
      }
      {!searchTerm && !loading && collections.length === 0 && !nextPage && <Welcome profile={profile} />}
      {searchTerm && !loading && !searchLoading && collections.length === 0 && <SearchMessage searchTerm={searchTerm} />}
      {loading && <ProgressBar><LinearProgress /></ProgressBar>}
    </Body>
  )
}
