import React, {useEffect, useState} from 'react'

import EditorContext from 'context/EditorContext'
import {LocalDirtyProvider} from 'context/LocalDirtyContext'
import {UnderlineProvider} from 'context/UnderlineContext'
import VideoPlayerActionsContext from 'context/VideoPlayerActionsContext'
import VideoPlayerStateContext from 'context/VideoPlayerStateContext'
import FindReplaceContext from 'context/FindReplaceContext'
import WordChunkEditorContext from 'context/WordChunkEditorContext'
import EditMenuContext from 'context/EditMenuContext'

const EditorContextsProvider = ({collectionId, isCorrector, isReviewer, locale, children}) => {
  
  const [editorContextValues, setEditorContextValues] = useState({
    collectionId,
    isCorrector,
    isReviewer,
    locale
  })

  useEffect(() => {
    setEditorContextValues({collectionId, isCorrector, isReviewer, locale})
  }, [collectionId, isCorrector, locale, isReviewer])

  const [videoPlayerActions, setVideoPlayerActions] = useState({
    setVideoPlayerActions: () => {},
    handleQuickRewind: () => {},
    onPlay: () => {},
    onPause: () => {},
    goSlow: () => {},
    goNormal: () => {},
    goFast: () => {},
    goFaster: () => {},
    onSeek: () => {}
  })
  useEffect(() => {
    setVideoPlayerActions(rest => ({...rest, setVideoPlayerActions}))
  }, [])

  const [videoPlayerState, setVideoPlayerState] = useState({
    setVideoPlayerState: () => {},
    fullScreen: false,
    large: false,
    popout: false,
    playing: false,
    speed: 1
  })

  const [findReplaceState, setFindReplaceState] = useState({
    setShow: () => {},
    show: false
  })

  const [wordChunkEditorState, setWordChunkEditorState] = useState({
    setShow: () => {},
    showWarning: false,
    showEditor: false,
    spans: [],
    minTime: 0,
    maxTime: 0,
    useBlockProps: {}
  })

  const [editMenuState, setEditMenuState] = useState({
    setShow: () => {},
    showContext: false,
    showHighlights: false,
  })

  useEffect(() => {
    setVideoPlayerState(rest => ({...rest, setVideoPlayerState}))
    setFindReplaceState(rest => ({...rest, setFindReplaceState}))
    setWordChunkEditorState(rest => ({...rest, setWordChunkEditorState}))
    setEditMenuState(rest => ({...rest, setEditMenuState}))
  }, [])

  return (
    <EditorContext.Provider value={{...editorContextValues, setEditorContextValues}}>
      <VideoPlayerActionsContext.Provider value={videoPlayerActions}>
        <VideoPlayerStateContext.Provider value={videoPlayerState}>
          <FindReplaceContext.Provider value={findReplaceState}>
            <WordChunkEditorContext.Provider value={wordChunkEditorState}>
              <EditMenuContext.Provider value={{...editMenuState, setEditMenuState}}>
                <UnderlineProvider>
                  <LocalDirtyProvider>
                    {children}
                  </LocalDirtyProvider>
                </UnderlineProvider>
              </EditMenuContext.Provider>
            </WordChunkEditorContext.Provider>
          </FindReplaceContext.Provider>
        </VideoPlayerStateContext.Provider>
      </VideoPlayerActionsContext.Provider>

    </EditorContext.Provider>
  )
}

export default EditorContextsProvider
