import React, {useState} from 'react'
import styled from 'styled-components'
import Popover from '@material-ui/core/Popover'
import {MenuItem} from 'components/common/Popover'
import useUpload from 'hooks/useUpload'
import {ReactComponent as DropBoxIcon} from 'static/images/dropbox.svg'
// import {ReactComponent as GoogleDriveIcon} from 'static/images/google_drive.svg'
import {ReactComponent as RecorderIcon} from 'static/images/recorder.svg'
import {ReactComponent as LinkIcon} from 'static/images/link.svg'

const Menu = styled.div`
  .PlRCV {
    margin-bottom: 10px;
    padding-right: 30px;
  }
`

const Span = styled.span`
  font-size: 12px;
`

export default (props) => {
  const {children, position} = props
  const {anchorOrigin, transformOrigin, margin, padding, borderRadius} = position

  const [anchorEl, setAnchorEl] = useState(null)
  const {openDialog} = useUpload()
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  const MoreVertIcon = document.getElementById('upload-list')
  const ArrowDropDownRounded = document.getElementById('upload-list-btn')

  const handleClick = event => {
    MoreVertIcon && MoreVertIcon.setAttribute('style', 'visibility: visible;')
    ArrowDropDownRounded && ArrowDropDownRounded.setAttribute('style', 'color: #FFF;')
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    MoreVertIcon && MoreVertIcon.removeAttribute('style', 'visibility: visible;')
    ArrowDropDownRounded && ArrowDropDownRounded.removeAttribute('style', 'color: #FFF;')
    setAnchorEl(null)
  }

  const openUploader = (view) => {
    openDialog({view, responsive: true})
  }

  const UploaderList = [
    {
      name: 'Dropbox',
      openUploader: () => openUploader('dropbox'),
      icon: () => <DropBoxIcon />
    },
    // {
    //   name: 'Google Drive',
    //   openUploader: () => openUploader('file-drop'),
    //   icon: () => <GoogleDriveIcon />
    // },
    {
      name: 'From URL',
      openUploader: () => openUploader('link'),
      icon: () => <LinkIcon />
    },
    {
      name: 'Recorder',
      openUploader: () => openUploader('recorder'),
      icon: () => <RecorderIcon />
    },
  ]

  return (
    <div className='upload-list'>
      <div
        tabIndex={0}
        role="button"
        styling="text"
        onKeyDown={(evt) => evt.keyCode === 13 && handleClick()}
        style={{outline: 'none', cursor: 'pointer'}}
        onClick={handleClick}
      >
        {children}
      </div>
      <Popover
        id={id}
        open={open}
        PaperProps={{
          style: {
            margin,
            padding,
            border: '1px solid #cecece',
            boxShadow: 'none',
            borderRadius: borderRadius || '6px',
            overflowX: 'initial',
            overflowY: 'initial'
          }
        }}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <>
          {
            UploaderList.map(({name, openUploader, icon}, i) => (
              <Menu key={i}>
                <MenuItem key={`uploader_${i}`} onClick={() => {
                  openUploader()
                  handleClose()
                }} color='#CF4923'>
                  {icon && icon()}
                  <Span>{name}</Span>
                </MenuItem>
              </Menu>
            ))
          }
        </>
      </Popover>
    </div>
  )
}
