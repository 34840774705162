import React from 'react'
import {ToastContainer, toast as parent} from 'react-toastify'

export const DEFAULT = {
  position: parent.POSITION.BOTTOM_RIGHT,
  type: parent.TYPE.INFO,
  pauseOnHover: true,
  className: 'toasterv2',
  hideProgressBar: true
}

export const POSITION = parent.POSITION
export const PARENT = parent

export const SUCCESS = {
  ...DEFAULT,
  className: 'toasterv2 toasterv2-success'
}

export const ERROR = {
  ...DEFAULT,
  className: 'toasterv2 toasterv2-error'
}

export const WARN = {
  ...DEFAULT,
  className: 'toasterv2 toasterv2-warn'
}

const success =  (text, options) => parent.success(text, {...SUCCESS, ...options})
export const toast = {
  success,
  info: success,
  error: (text, options) => parent.error(text, {...ERROR, ...options}),
  warn: (text, options) => parent.warn(text, {...WARN, ...options}),
  isActive: (id) => parent.isActive(id),
  dismissAll: () => parent.dismiss(),
  dismiss: (id) => parent.dismiss(id),
  update: (id, options) => parent.update(id, {...options}),
  factory: parent
}

export default (props) => <ToastContainer {...props} />
