import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  current
} from '@reduxjs/toolkit'
import _assign from 'lodash/assign'
import _compact from 'lodash/compact'
import _get from 'lodash/get'
import _has from 'lodash/has'
import _keys from 'lodash/keys'
import _omit from 'lodash/omit'
import _map from 'lodash/map'
import _set from 'lodash/set'
import _sortBy from 'lodash/sortBy'
import _uniq from 'lodash/uniq'
import _unset from 'lodash/unset'
import _chunk from 'lodash/chunk'

import {
  addSpeaker as addSpeakerApi,
  fetchSpeakers as fetchSpeakersApi,
  removeSpeaker as removeSpeakerApi,
  updateSpeaker as updateSpeakerApi,
  updateSpeakers as updateSpeakersApi
} from 'api/speakers'

import {
  selectCollectionFirstWord
} from 'features/transcripts'

// Slice
export const speakersAdapter = createEntityAdapter()
const initialState = speakersAdapter.getInitialState({
  pendingRequests: []
})

// Selectors
const sliceSelector = state => state.speakers
const {selectById} = speakersAdapter.getSelectors(sliceSelector)

export const selectCollectionSpeakers = createSelector(
  [selectById, selectCollectionFirstWord],
  (speakersObject, firstWord) => {
    const firstWordStartTime = _get(firstWord, 'start_time', 0)
    const speakers = _omit(speakersObject, 'id')
    const firstSpeakerKeyString = _keys(speakers)[0]
    const firstSpeakerKeyFloat = firstSpeakerKeyString ? parseFloat(firstSpeakerKeyString) : null
    if (!firstSpeakerKeyFloat || firstSpeakerKeyFloat > firstWordStartTime) {
      return {[firstWordStartTime]: {name:''}, ...speakers}
    }
    return speakers
  }
)

export const selectUniqueSpeakerNames = createSelector(
  [selectCollectionSpeakers],
  speakers => _compact(_uniq(_map(speakers, 'name')))
)

export const selectUniqueSpeakerNameObjects = createSelector(
  [selectUniqueSpeakerNames],
  names => _map(names, name => ({name}))
)

export const selectSpeakerById = createSelector(
  [
    selectCollectionSpeakers,
    (_, __, sectionId) => sectionId
  ],
  (speakers, sectionId) => _get(speakers, sectionId, {}) || {}
)

export const selectSpeakerNameBySectionId = createSelector(
  [
    selectSpeakerById,
    (_, __, sectionId) => sectionId
  ],
  (speaker, sectionId) => _get(speaker, 'name', null)
)

export const selectSpeakerIds = createSelector(
  [selectCollectionSpeakers],
  speakers => _sortBy(_map(speakers, (speaker, id) => parseFloat(id)))
)

// Actions
const speakerExistsInState = (state, collectionId, speakerId) =>
  _has(state, ['entities', collectionId, speakerId])

export const addSpeaker = createAsyncThunk(
  'speakers/addOne',
  async ({collectionId, startTime, locale}) => {
    try {
      await addSpeakerApi({collectionId, startTime, locale})
    } catch (error) {
      console.log(error)
    }
  }
)

export const fetchSpeakers = createAsyncThunk(
  'speakers/fetch',
  async ({collectionId, locale}) => {
    try {
      const {data} = await fetchSpeakersApi({collectionId, locale})
      data.id = collectionId
      return data
    } catch (error) {
      console.log('unable to fetch speakers', collectionId, error)
    }
  }
)

export const updateSpeaker = createAsyncThunk(
  'speakers/updateOne',
  async ({collectionId, speakerId, name, locale}, {getState, dispatch}) => {
    try {
      const state = getState()
      const speakerExists = speakerExistsInState(state.speakers, collectionId, speakerId)

      if (!speakerExists) {
        await addSpeakerApi({collectionId, speakerId, name, locale})
        return
      }

      await updateSpeakerApi({collectionId, speakerId, name, locale})
    } catch (error) {
      console.log(error)
    }
  }
)

export const updateSpeakers = createAsyncThunk(
  'speakers/updateMany',
  async ({collectionId, speakers: initSpeakers, locale}, {getState, dispatch}) => {
    try {
      let calls = []
      // break it up into a max size
      const speakerIdChunks = _chunk(Object.keys(initSpeakers), 150)
      // push chunks
      for (const speakerIdChunk of speakerIdChunks) {
        const speakers = speakerIdChunk.reduce((obj, id) => {
          obj[id] = initSpeakers[id]
          return obj
        }, {})
        calls.push(updateSpeakersApi({collectionId, speakers, locale}))
      }
      await Promise.all(calls)
      return initSpeakers
    } catch (error) {
      console.log(error)
    }
  }
)

export const removeSpeaker = createAsyncThunk(
  'speakers/removeOne',
  async ({collectionId, speakerId, locale}) => {
    try {
      await removeSpeakerApi({collectionId, speakerId, locale})
    } catch (error) {
      console.log(error)
    }
  }
)

export const clearAllSpeakerNames = createAsyncThunk(
  'speakers/clear/names',
  async ({collectionId, locale},{getState, dispatch}) => {
    try {
      const state = getState()
      // clear names
      const collectionSpeakers = state.speakers.entities[collectionId]
      const speakers = Object.keys(collectionSpeakers).reduce((acc, key) => {
        if (key === 'id') return acc
        acc[key] = {...collectionSpeakers[key], name: ''}
        return acc
      }, {})
      return dispatch(updateSpeakers({collectionId, speakers, locale}))
    } catch (error) {
      console.log('Could not clear speakers', error)
      return Promise.reject('could not complete update')
    }
  }
)

export const slice = createSlice({
  name: 'speakers',
  initialState,
  reducers: {},
  extraReducers: builder => {
    const addRequestId = (state, {meta: {requestId}}) => {
      state.pendingRequests.push(requestId)
    }

    const removeRequestId = (state, {meta: {requestId}}) => {
      state.pendingRequests = state.pendingRequests.filter(id => id !== requestId)
    }

    builder
      .addCase(addSpeaker.fulfilled, removeRequestId)
      .addCase(addSpeaker.rejected, removeRequestId)
      .addCase(updateSpeaker.rejected, removeRequestId)
      .addCase(removeSpeaker.fulfilled, removeRequestId)
      .addCase(updateSpeakers.rejected, removeRequestId)
      .addCase(removeSpeaker.rejected, removeRequestId)
      .addCase(fetchSpeakers.fulfilled, (state, {meta, payload}) => {
        speakersAdapter.upsertOne(state, payload)
      })
      .addCase('collections/fetch/fulfilled', (state, {meta, payload}) => {
        const {arg: {id: collectionId, versionId} = {}} = meta

        if (!versionId) return

        const {speakers} = payload
        speakers.id = collectionId
        _set(state, ['entities', collectionId], speakers)
      })
      .addCase(addSpeaker.pending, (state, args) => {
        const {meta: {arg: {collectionId, startTime}}} = args
        _set(state, ['entities', collectionId, startTime], {name: ''})
        addRequestId(state, args)
      })
      .addCase(removeSpeaker.pending, (state, args) => {
        const {meta: {arg: {collectionId, speakerId}}} = args
        _unset(state, ['entities', collectionId, speakerId])
        addRequestId(state, args)
      })
      .addCase(updateSpeaker.pending, (state, args) => {
        const {meta: {arg: {collectionId, speakerId, name}}} = args
        const speakerExists = speakerExistsInState(current(state), collectionId, speakerId)
        if (speakerExists) _set(state, ['entities', collectionId, speakerId, 'name'], name)
        addRequestId(state, args)
      })
      .addCase(updateSpeaker.fulfilled, (state, args) => {
        const {meta: {arg: {collectionId, speakerId, name}}} = args
        const speakerExists = speakerExistsInState(current(state), collectionId, speakerId)
        if (!speakerExists) _set(state, ['entities', collectionId, speakerId, 'name'], name)
        removeRequestId(state, args)
      })
      .addCase(updateSpeakers.pending, (state, args) => {
        // const {meta: {arg: {collectionId, speakers}}} = args
        // _assign(state.entities[collectionId], speakers)
        addRequestId(state, args)
      })
      .addCase(updateSpeakers.fulfilled, (state, args) => {
        const {meta: {arg: {collectionId, speakers}}} = args
        _assign(state.entities[collectionId], speakers)
        removeRequestId(state, args)
      })
  }
})

const reducer = slice.reducer
export default reducer
