import React, {Suspense, lazy} from 'react'
import {Route, Switch} from 'react-router-dom'
import {UserIsAuthenticated, UserIsCorrector, UserIsCorrectorAdmin} from 'utils/Auth'
// Core pages 
import SimpleSignup from 'sites/app/pages/SimpleSignup'
import SimpleLogin from 'sites/app/pages/SimpleLogin'
import Reset from 'sites/app/pages/Reset'
import RequestReset from 'sites/app/pages/RequestReset'
import Tracker from 'utils/Tracker'
import DashboardV2 from 'sites/app/containers/DashboardV2'
// import NotFound from 'sites/app/pages/404'
// utils
import Loading from 'components/common/Loading'
import {isNotCompatible} from 'components/common/BrowserSupportModal'

// LAZY LOADING for smaller buidl footprint in order of importance
// "Code Splitting": https://reactjs.org/docs/code-splitting.html#reactlazy
const Settings = lazy(() => import('sites/app/containers/Settings'))
const Plans = lazy(() => import('sites/app/containers/Plans'))
const Payment = lazy(() => import('sites/app/pages/Payment'))
const Corrector = lazy(() => import('sites/app/containers/Corrector'))

const CorrectorAdmin = lazy(() => import('sites/app/pages/CorrectorAdmin'))

const EditV2 = lazy(() => import('sites/app/containers/EditV2'))
const VideoPopout = lazy(() => import('sites/app/pages/VideoPopout'))
// functional pages
const Logout = lazy(() => import('sites/app/pages/Logout'))
const NotFound = lazy(() => import('sites/app/pages/404'))
const Unsupported = lazy(() => import('sites/app/pages/Unsupported'))
const NotAllowed = lazy(() => import('sites/app/pages/401'))
// support pages
const Diagnostics = lazy(() => import('sites/app/containers/Diagnostics'))
const Maintenance = lazy(() => import('sites/app/pages/Maintenance'))
const ForcedErrorPage = lazy(() => import('sites/app/pages/ForcedErrorPage'))

const CustomPageSettings = {
  nyu: {
    active: true,
    buttonColor: '#57068b',
    backgroundColor: '#57068b',
    allowGoogle: false,
    allowEmail: true,
    logo: 'https://firebasestorage.googleapis.com/v0/b/konch-eu.appspot.com/o/Organizations%2F-nyu9238uyfeqwo0i%2Fnyu_custom_page_logo.png?alt=media&token=89aad084-52f4-4672-a7bb-84646e03184e'
  },
  deic: {
    active: true,
    allowGoogle: false,
    allowEmail: true,
    buttonColor: '#000',
    backgroundColor: '#fcffe7',
    organization: '-deicunikeyfororg09252018',
    // provider: 'saml.wayf',
    // xml: 'https://metadata.wayf.dk/wayf-metadata.xml',
    // logo: 'https://firebasestorage.googleapis.com/v0/b/konch-eu.appspot.com/o/Organizations%2F-deicunikeyfororg09252018%2Fdeic_custom_page_logo.svg?alt=media&token=72204a2f-6e29-479e-9843-62d7651e8c8b'
  },
  lunduniversity: {
    active: false,
    allowGoogle: false,
    allowEmail: true,
    buttonColor: '#466197',
    backgroundColor: '#f9f8f4',
    logo: 'https://firebasestorage.googleapis.com/v0/b/konch-eu.appspot.com/o/Organizations%2F-lunduniversity-839x203-12a219332%2Flund_custom_page_logo.png.svg?alt=media&token=d066633b-08d2-44b7-bfb9-84f09b97ee51'
  },
  jump: {
    active: false,
    name: 'Jump Demo',
    allowGoogle: false,
    allowEmail: false,
    buttonColor: '#466197',
    backgroundColor: '#f9f8f4',
    provider: 'saml.jump-prod-demo',
    organization: '-jumpdemo9io329e3'
  },
  wayf: {
    active: true,
    name: 'WAYF Test Site',
    allowGoogle: false,
    allowEmail: false,
    buttonColor: '#466197',
    backgroundColor: '#f9f8f4',
    provider: 'saml.wayf',
    xml: 'https://metadata.wayf.dk/wayf-metadata.xml',
    organization: '-wayfdemo97yy13e9'
  },
  nordunet: {
    active: false,
    name: 'NordUnet',
    allowGoogle: false,
    allowEmail: true,
    buttonColor: '#045ab2',
    backgroundColor: '#01a2dd',
    provider: 'saml.wayf',
    xml: 'https://metadata.wayf.dk/wayf-metadata.xml',
    organization: '-nordunetm3pF9c3CeoLZO',
    logo: 'https://firebasestorage.googleapis.com/v0/b/konch-eu.appspot.com/o/Organizations%2F-nordunetm3pF9c3CeoLZO%2Fnordunet_custom_page_logo.png?alt=media&token=064fa68c-0349-4d98-b434-166cd75546d4'
  },
  cbs: {
    active: true,
    name: 'Copenhagen Business School',
    allowGoogle: false,
    allowEmail: true,
    buttonColor: '#4967aa',
    backgroundColor: '#f7f7f7',
    organization: '-cbs12981hdnc-ou8eoiu',
    logo: 'https://firebasestorage.googleapis.com/v0/b/konch-eu.appspot.com/o/Organizations%2F-cbs12981hdnc-ou8eoiu%2Fcbs_custom_page_logo.png?alt=media&token=e6525c3b-627f-4f7b-875a-f7e4729b439d',
    provider: 'saml.wayf',
    xml: 'https://metadata.wayf.dk/wayf-metadata.xml'
  },
  aau: {
    active: true,
    name: 'Aalborg Universitet',
    allowGoogle: false,
    allowEmail: true,
    buttonColor: '#20224d',
    backgroundColor: '#f7f7f7',
    organization: '-aau7g982022nc-ou9fUgk',
    logo: 'https://firebasestorage.googleapis.com/v0/b/konch-eu.appspot.com/o/Organizations%2F-aau7g982022nc-ou9fUgk%2Faau_custom_page_logo.png?alt=media&token=2a7cff45-cec5-4fa0-8971-5de9c5c3a399'
  },
  sdv: {
    active: false,
    name: 'SDV International',
    allowEmail: true,
    buttonColor: '#444',
    backgroundColor: '#dedede',
    organization: '-sdvinternational9hd298yd12',
    logo: 'https://firebasestorage.googleapis.com/v0/b/konch-eu.appspot.com/o/Organizations%2F-sdvinternational9hd298yd12%2Fsdv_custom_page_logo.png?alt=media&token=99caa93e-e09b-49ff-9b09-d3e474f6c3b7'
  },
  storycorps: {
    active: true,
    name: 'StoryCorps',
    allowEmail: true,
    buttonColor: '#EE2A3E',
    backgroundColor: '#FAFAFA',
    organization: '-storycorps9023ohs9f3iup',
    logo: 'https://firebasestorage.googleapis.com/v0/b/konch-eu.appspot.com/o/Organizations%2F-storycorps9023ohs9f3iup%2Fstorycorps_custom_page_logo.svg?alt=media&token=5b85337d-b977-4fa3-8a34-bd7e51de3513'
  }
}

const MemberDirect = (Page, method) => {
  const hostList = window.location.host.split('.')
  const subdomain = hostList[0].toLowerCase()
  const whiteLabeledSubdomains = Object.keys(CustomPageSettings)
  if (whiteLabeledSubdomains.indexOf(hostList[0]) >= 0) {
    return Tracker(Page, {settings: CustomPageSettings[subdomain]})
  }
  return Tracker(Page, {organization: 'default'})
}

const IS_MAINTENANCE = false // window.localStorage.getItem('dowhat') !== 'doit'
export default () => {
  if (IS_MAINTENANCE) {
    return <Switch><Route component={Maintenance} /></Switch>
  } else if (isNotCompatible()) {
    return <Route component={Unsupported} />
  } else {
    return (
      <Suspense fallback={<Loading dark />}>
        <Switch>
          <Route exact path='/' component={MemberDirect(SimpleSignup)} />
          <Route exact path='/register' component={MemberDirect(SimpleSignup)} />
          <Route exact path='/signin' component={MemberDirect(SimpleLogin)} />
          <Route exact path='/dashboard' component={UserIsAuthenticated(Tracker(DashboardV2))} />
          <Route exact path='/reset/request' component={MemberDirect(RequestReset)} />
          <Route exact path='/reset' component={MemberDirect(Reset)} />
          <Route exact path='/plans' component={Tracker(Plans)} />
          <Route exact path='/settings' component={UserIsAuthenticated(Tracker(Settings))} />
          <Route exact path='/settings/:page' component={UserIsAuthenticated(Tracker(Settings))} />
          <Route exact path='/settings/:page/:id' component={UserIsAuthenticated(Tracker(Settings))} />
          <Route exact path='/payment/:action' component={UserIsAuthenticated(Tracker(Payment))} />
          <Route exact path='/video/p' component={UserIsAuthenticated(Tracker(VideoPopout))} />
          <Route exact path='/edit-v2/:id' component={UserIsAuthenticated(Tracker(EditV2))} />
          <Route exact path='/corrector' component={UserIsCorrector(Tracker(Corrector))} />
          <Route exact path='/corrector/guide' component={UserIsCorrector(Tracker((props) => <Corrector guide {...props} />))} />
          <Route exact path='/corrector/admin/:adminPage' component={UserIsCorrectorAdmin(CorrectorAdmin)} />
          <Route exact path='/corrector/:locale/:type/:id' component={UserIsCorrector(Tracker(EditV2))} />
          <Route exact path='/corrector/:locale/:type/:id' component={UserIsCorrector(Tracker(EditV2))} />
          <Route exact path='/not_allowed' component={NotAllowed} />
          <Route exact path='/logout' component={Logout} />
          <Route exact path='/unsupported' component={Unsupported} />
          <Route exact path='/d' component={UserIsAuthenticated(Diagnostics)} />
          <Route exact path='/forced_error' component={ForcedErrorPage} />
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    )
  }
}
