import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
// import {faClipboard} from '@fortawesome/free-regular-svg-icons'
import {faUpload} from '@fortawesome/free-solid-svg-icons'
// import {faGoogleDrive} from '@fortawesome/free-brands-svg-icons'
import Tooltip from '@material-ui/core/Tooltip'
// import PasteUrl from 'components/common/CreatorDialog/PasteUrl'
import MicIcon from '@material-ui/icons/Mic'
import LinkIcon from '@material-ui/icons/Link'
import useUpload from 'hooks/useUpload'
import KalturaIcon from 'static/images/kaltura-icon-25.png'
import FitScreenIcon from 'static/images/fit_screen.svg'

import {faDropbox} from '@fortawesome/free-brands-svg-icons'

const RootCont = styled.div`
  right: 24px;
  bottom: 32px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  user-select: none;
  flex-direction: column;
  color: white;
`

const CreateButton = styled.div`
  cursor: pointer;
  background-color: #5173E7;
  box-shadow: 0 4px 13px 0 rgba(0,0,0,0.12);
  font-size: 40px;
  font-weight: 500;
  width: 62px;
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding-bottom: 4px;
  transition: transform ease 200ms;
  z-index: 1;
  :hover {
    transform: rotate(90deg);
  }
`

const OtherButton = styled.div`
  cursor: pointer;
  width: 32px;
  height: 32px;
  padding: 11px;
  border-radius: 50%;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #5173E7;
  text-align: center;
  font-size: 15px;
  transform: translateY(50px);
  opacity: 0;
  transition: transform .3s, opacity .2s, background-color 1s;
`

const RootButton = styled.div`
  position: fixed;
  bottom: 32px;
  width: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  flex-direction: column;
  color: white;
  ${({show}) => show ? `
    opacity: 1;
    transform: translateY(0px);
  ` : `
    opacity: 0;
    transform: translateY(200px);
  `}
  transition: transform .9s, opacity 1s;
  &:hover ${CreateButton}  {
    transform: rotate(90deg);
  }
  &:hover ${OtherButton} {
    transform: translateY(0px);
    opacity: 1;
    transition: transform 1s, opacity .6s;
    &:hover {
      background-color: #7395F9;
      transition: background-color 1s;
    }
  }
`

const FitScreenImg = styled.img`
  width: 19px;
`

export default () => {
  const {openDialog} = useUpload()
  const [show] = useState(true)
  /**
   * TODO 2: Get openPicker from the useGoogleDrivePicker hook.
   * TODO 3: Create function handleOpenGoogleDrivePicker that calls openPicker with the required config to open the 
   * picker.
   */
  const openUploader = (view) => {
    openDialog({view, responsive: true})
  }

  useEffect(() => {
    // const onScroll = () => {
    //   if (window.pageYOffset > 100) {
    //     setShow(true)
    //   } else {
    //     setShow(false)
    //   }
    // }
    // window.addEventListener('scroll', onScroll)

    // return () => window.removeEventListener('scroll', onScroll)
  }, [])

  return (
    <RootCont>
      <RootButton  show={show}>
        {
          localStorage.getItem('__sakk') &&
          <Tooltip
            title='Kaltura'
            placement='left'>
            <OtherButton onClick={() => openUploader('kaltura')}>
              <img alt='Kaltura icon' style={{width: '19px'}} src={KalturaIcon} />
            </OtherButton>
          </Tooltip>
        }

        {/**
         * TODO 1: Add Google Drive button.
         * - When its clicked it calls handleOpenGoogleDrivePicker that opens the Picker.
         * - Google drive icon would be sourced from font-awesome.
         **/
          /**
        <Tooltip
          title='Google Drive'
          placement='left'>
          <OtherButton onClick={() => openUploader('google-drive')}>
            <FontAwesomeIcon icon={faGoogleDrive} />
          </OtherButton>
        </Tooltip>
        ***/}

        {
          window.navigator?.mediaDevices?.getDisplayMedia && 
          <Tooltip
            title='Screen Recorder'
            placement='left'>
            <OtherButton onClick={() => openUploader('screen-recorder')}>
              <FitScreenImg alt='fit screen icon' src={FitScreenIcon}/>
            </OtherButton>
          </Tooltip>
        }

        <Tooltip
          title='Dropbox'
          placement='left'>
          <OtherButton onClick={() => openUploader('dropbox')}>
            <FontAwesomeIcon icon={faDropbox} />
          </OtherButton>
        </Tooltip>
        <Tooltip
          title='Link Upload'
          placement='left'>
          <OtherButton onClick={() => openUploader('link')}>
            <LinkIcon />
          </OtherButton>
        </Tooltip>
        <Tooltip
          title='Web Recorder'
          placement='left'>
          <OtherButton onClick={() => openUploader('recorder')}>
            <MicIcon />
          </OtherButton>
        </Tooltip>
        <Tooltip
          title='Upload File'
          placement='left'>
          <OtherButton onClick={() => openUploader('file-drop')}>
            <FontAwesomeIcon icon={faUpload} />
          </OtherButton>
        </Tooltip>
        <CreateButton onClick={() => openUploader('file-drop')}>+</CreateButton>
      </RootButton>
    </RootCont>
  )
}