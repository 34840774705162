import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {Link} from 'react-router-dom'
import CountUp from 'react-countup'

import LinearProgress from '@material-ui/core/LinearProgress'
// import FadeIn from 'react-fade-in'
import {Fade} from '@material-ui/core'

import Loader from 'components/common/Loaders/Skeleton'
import useSubscription from 'hooks/useSubscriptions'
import usePlan from 'hooks/usePlan'
import useProfile from 'hooks/useProfile'
import useOrganization from 'hooks/useOrganization'

const Root = styled.div`
  align-items: flext-start;
  justify-content: flext-start;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  width: 122px;
  h4 {
    margin-bottom: 12px;
  }
  h5 {
    display: flex;
    justify-content: space-between;
    margin: 0;
    color: #999;
    text-transform: uppercase;
    font-size: 11px;
    .hover {
      opacity: 0;
      transition: opacity 1s;
      transform: scale(.8);
      transition: color 1s, opacity 1s, transform 1s;
    }
  }

  :hover .hover {
    opacity: 1;
    :hover {
      transform: scale(.9);
      color: dodgerblue;
      transition: color 1s, transform 1s;
    }
  }

  .MuiLinearProgress-root, .bar {
    border-radius: 6px;
    height: 6px;
    margin-top: 10px;
  }
  .MuiLinearProgress-colorPrimary {
    background-color: #CCC;
  }
  .MuiLinearProgress-barColorSecondary {
   background-color: #1d6fb7;
  }
  .MuiLinearProgress-bar2Buffer {
    background-color: #BBB;
    background: repeating-linear-gradient(
      45deg,
      #AAA,
      #AAA 5px,
      #BBB 5px,
      #BBB 10px
    );
    border-radius: 5px;
  }
  .MuiLinearProgress-dashed {
    display: none;
  }
  .MuiLinearProgress-barColorPrimary {
    ${({over}) => over && `
      background-color: #CCC;
    `}
  }
  small {
    margin: 8px 0 15px 0;
    color: #AAA;
    font-size: .75em;
  }
`

const TimeLeft = ({minutes}) => {
  // console.log({minutes})
  if (minutes > 999) {
    const hours = Math.round(minutes / 60)
    return <span><CountUp start={0} end={hours} duration={2} /> hours</span>
  }
  // we round to the highest minute so no need to show seconds
  // if (minutes < 1) {
  //   return <span><CountUp start={0} end={60 * minutes} duration={2} /> seconds</span>
  // }

  return <span><CountUp start={0} end={minutes} duration={2} /> minutes</span>
}

const UploadProgress = (props) => {
  const {uid, oid, billing, isLoaded} = useProfile()
  const {updateBilling} = useOrganization({oid})
  const {enterprise, updated, paymentMethod} = billing
  const {fetchUsage, loading, fetchCredits, calcForUsageMeter} = usePlan({oid, uid})
  const {fetchPaymentMethods} = useSubscription(billing)
  const [loaded, setLoaded] = useState(false)

  const {
    perc,
    estPerc,
    minutesLeft,
    paygMins,
    estimatedDraft,
    draftPlan
  } =  calcForUsageMeter

  const validateCard = (card) => {
    if (!card) return false
    const today = new Date()
    const expirationDate = new Date(card.exp_year, card.exp_month, 0)
    return card.checks.cvc_check === 'pass' && expirationDate > today
  }
  
  const init = async () => {
    if (!loading && oid && uid && !draftPlan) {
      const [usage, credits, paymentMethods] = await Promise.all([
        fetchUsage(),
        fetchCredits(),
        fetchPaymentMethods()
      ])
      console.log({usage, credits, paymentMethods})
      if (paymentMethods) {
        const activePaymentMethod = paymentMethods.find(pm => validateCard(pm?.card))
        console.log({activePaymentMethod})
        // use the chance to update payment cache in FB if was null or different now
        if (activePaymentMethod && (!paymentMethod || paymentMethod !== activePaymentMethod.id)) {
          updateBilling('paymentMethod', activePaymentMethod.id)  
        }
      }
      setLoaded(true)
    } 
  }

  useEffect(() => {
    init()
  }, [oid, updated])

  // enterprise dont show anything
  if (enterprise) return ''

  // Loading....
  if (!isLoaded  || (!loaded && loading && !enterprise)) return <div style={{marginTop: '20px'}}><Loader  width='100px' /><Loader /></div>

  // no plan or big plan or mins and assume there is an admin watching minites
  if ((!draftPlan && !paymentMethod && paygMins === undefined) || draftPlan > 59999) return ''

  const activePlan = draftPlan > 0 && minutesLeft > 0 && billing.subscription
  const fixPaymentMethod = !paymentMethod && paygMins === 0 && !activePlan 
  const showPAYG = paygMins >= 0 || paymentMethod

  if (!showPAYG && !fixPaymentMethod && !activePlan) return <Root><Link to='/plans'><small>No more minutes left.</small></Link> </Root>

  return (
    <Fade in={true} timeout={1000}>
      <Root over={perc >= 100 ? 1 : 0} >
        <h4>Usage</h4>
        {
          activePlan ?
            <>
              <h5>
                <div>Subscription</div>
                <div className='hover'><Link to='/settings/invoices#invoices'>More</Link></div>
              </h5>
              <LinearProgress style={{marginBottom: '8px'}} variant="buffer" value={perc} valueBuffer={estPerc}  />
              <small style={{marginBottom: '15px'}}>{estimatedDraft >= 0 ? <span><TimeLeft minutes={minutesLeft} /> left</span> : ''} </small>
            </> : ''
        }
        { 
          fixPaymentMethod ? <Link to='/plans'><small>Fix payment method</small></Link> :
            showPAYG ?
              <>
                <h5 style={{marginBottom: '5px'}}>
                  <div>Pay as you go</div>
                  <div className='hover'><Link to='/settings/invoices#adjustments'>More</Link></div>
                </h5>
                {paygMins ? <small><TimeLeft minutes={paygMins} /> in credits</small> : ''}
              </> : ''
        }
      </Root>
    </Fade>
  )
}

export default UploadProgress
