import {createContext} from 'react'

const EditMenuContext = createContext({
  setShow: () => {},
  showContext: false,
  top: undefined,
  left: undefined,
  lastSpanPosition: undefined,
  startTime: undefined,
  endTime: undefined,
  maxTime: undefined,
  minTime: undefined,
  isHighlight: undefined,
  isStrike: undefined,
  isBold: undefined,
  isItalic: undefined,
  isCorrect: undefined,
  isCorrector: undefined
})

export default EditMenuContext
