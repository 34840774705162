import React, {useContext, Fragment} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faFileWord,
  faClosedCaptioning,
  faFileCsv,
  faFileAlt
} from '@fortawesome/free-solid-svg-icons'

import EditorContext from 'context/EditorContext'
import useExporter from 'hooks/useExporter'
import useIsDirty from 'hooks/useIsDirty'
import useProfile from 'hooks/useProfile'

import ProgressBar from 'components/common/ProgressBar'
import Button from 'components/common/Button'
import Popover, {MenuItem, MenuIcon} from 'components/common/Popover'
import Spinner from 'components/common/Spinner'

const Export = React.memo(({children}) => {
  const {collectionId, isCorrector, isReviewer} = useContext(EditorContext)
  const isDirty = useIsDirty()
  const {settings: {maxCPL = 35}} = useProfile()
  const {
    loading,
    generateAnnotations,
    generateDoc,
    generateText,
    generateSrtSubtitles,
    generateVttSubtitles,
    downloadCsv
  } = useExporter({collectionId})

  if (isCorrector || isReviewer) return null

  return (
    <Fragment>
      {loading && <ProgressBar />}
      <Popover
        leftTransform='-50%'
        trigger={children || <Button primary raised>Export</Button>}
        content={
          <>
            <MenuItem onClick={() => generateDoc()}>
              <MenuIcon>
                <FontAwesomeIcon icon={faFileWord} />
              </MenuIcon>

              <span>Word Doc</span>
            </MenuItem>

            <MenuItem onClick={() => generateText()}>
              <MenuIcon>
                <FontAwesomeIcon icon={faFileAlt} />
              </MenuIcon>

              <span>Plain Text</span>
            </MenuItem>

            <MenuItem onClick={() => downloadCsv()}>
              <MenuIcon>
                <FontAwesomeIcon icon={faFileAlt} />
              </MenuIcon>

              <span>CSV</span>
            </MenuItem>

            <Popover
              topTransform='-50%'
              trigger={
                <MenuItem>
                  <MenuIcon>
                    {
                      isDirty
                        ? <Spinner small color='#7DBB91' />
                        : <FontAwesomeIcon icon={faClosedCaptioning} />
                    }
                  </MenuIcon>

                  <span>Subtitles</span>
                </MenuItem>
              }
              content={
                <div>
                  <MenuItem onClick={() => generateSrtSubtitles(maxCPL)}>
                    <span>SRT</span>
                  </MenuItem>
                  <MenuItem onClick={() => generateVttSubtitles(maxCPL)}>
                    <span>VTT</span>
                  </MenuItem>
                </div>
              }
            />

            <Popover
              topTransform='-50%'
              trigger={
                <MenuItem>
                  <MenuIcon>
                    {
                      isDirty
                        ? <Spinner small color='#7DBB91' />
                        : <FontAwesomeIcon icon={faFileCsv} />
                    }
                  </MenuIcon>
                  <span>Highlihts/Comments</span>
                </MenuItem>
              }
              content={
                <div>
                  <MenuItem onClick={() => generateAnnotations(true)}>
                    <span>CSV</span>
                  </MenuItem>
                  <MenuItem onClick={() => generateAnnotations()}>
                    <span>XLS</span>
                  </MenuItem>
                </div>
              }
            />
          </>
        }
      />
    </Fragment>
  )
})

export default Export
