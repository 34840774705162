import React from 'react'
import styled from 'styled-components'
import Avatar from 'react-user-avatar'
import Zoom from '@material-ui/core/Zoom'
import LightTooltip from 'components/common/LightTooltip'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faUser} from '@fortawesome/free-solid-svg-icons'
import Skeleton from '@material-ui/lab/Skeleton'

const Root = styled.div`
  display: inline-block;
  cursor: default;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
`

const UserAvatar = styled(Avatar)`
  box-sizing: border-box;
  ${props => !props.noBorder && 'border: 2px solid rgba(150,150,150,0.1);'}
  ${props => props.border && `border: ${props.border};`}
  ${props => props.size && `
    border-radius: 50%;
    width: ${props.size + 4}px;
    height: ${props.size + 4}px;
    font-size: ${props.fontSize ? props.fontSize : (props.size / 2.1) - ((props.size / 2.1) * 0.1)}px;
  `}
  text-transform: capitalize;
  color: #FFF;
  & .UserAvatar--inner {
    ${props => props.lineHeight && `line-height: ${props.lineHeight}px !important;`}
    ${props => props.padding && `padding: ${props.padding};`}
    :
  }
`

const NoAvatarCont = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${({size = 25}) => `
    width: ${size};
    height: ${size};
  `}
  color: #FFF;
  border-radius: 50%;
`

export default (props) => {
  const {
    profile: user,
    name: nameStr,
    email: emailStr,
    avatar: avatarStr,
    showTip = true,
    size,
    color,
    className,
    style,
    noBorder,
    fontSize,
    padding,
    delay,
    lineHeight,
    onClick,
    border,
    tipPlacement,
    isLoading
  } = props
  const {name = nameStr, email = emailStr, avatar = avatarStr} = user || {}
  const circleSize = size || 35
  const pixels = `${circleSize}px`
  const isEmpty = !name && !email && !avatar

  if (isLoading) return <Skeleton variant='circle' width={40} height={40} />

  if (isEmpty) return <NoAvatarCont size={pixels}><FontAwesomeIcon icon={faUser} /></NoAvatarCont>
  const garunteedName = (email || name || '-').split(' ').slice(0, 2).map((s) => s.charAt(0).toUpperCase()).join(' ')
  const avtr = (
    <Root style={style} className={className} onClick={() => onClick && onClick()}>
      {
        name || email
          ? <UserAvatar
            noBorder={noBorder}
            fontSize={fontSize}
            padding={padding}
            lineHeight={lineHeight}
            color={color}
            src={avatar}
            name={garunteedName}
            size={circleSize}
            border={border} />
          : <div style={{width: `${circleSize}px`, height: `${circleSize}px`, borderRadius: '50%', backgroundColor: '#CCC'}} />
      }
    </Root>
  )
  
  if (!showTip) return avtr

  return <LightTooltip TransitionComponent={Zoom} title={showTip ? email || name || '' : ''} placement={tipPlacement || 'top'} enterDelay={delay || 600}>{avtr}</LightTooltip>
}
