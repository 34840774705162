import React from 'react'

import Chip from '@material-ui/core/Chip'
import styled from 'styled-components'
import InsertEmoticon from '@material-ui/icons/InsertEmoticon'

import LightTooltip from 'components/common/LightTooltip'

import UpgradeModal from 'components/common/EditorV2/UpgradeModal'
import Spinner from 'components/common/Spinner'

const StyledChip = styled(Chip)`
  color: ${({primarycolor}) => primarycolor || '#7dbb90'} !important; 
  background-color: ${({bgcolor}) => bgcolor || 'transparent'} !important;
  border-color: ${({bordercolor}) => bordercolor || '#7dbb90'} !important;
  margin-left: -5px; 
  font-weight: bold;
  font-size: 11px !important;
  text-transform: uppercase;

  ${({collapsed}) => collapsed && `
      .MuiChip-labelSmall {
        opacity: 0;
        transition: opacity .5s;
      }
      &.MuiChip-outlined {
        border-color: transparent !important;
        transition: border-color .5s;
      }
      
      :hover {
        .MuiChip-labelSmall {
          opacity: 1;
          transition: opacity .5s;
        }
        &.MuiChip-outlined {
          border-color: #7dbb90 !important;
          transition: border-color .5s;
        }
      }
  `}
`

const Default = (props = {}) => {
  const {
    onClick, 
    description, 
    label, 
    style,
    className,
    collapsed,
    processing,
    rightIcon,
    leftIcon,
    primaryColor = '#7dbb90',
    bgColor,
  } = props || {}
  
  return (
    <LightTooltip 
      title={(
        <div style={{padding: '10px', lineHeight: '1.5em', fontSize: '13px', fontWeight: 'normal'}}>
          {description}
        </div>
      )}
    >
      <StyledChip
        className={className}
        collapsed={collapsed ? 1 : 0}
        style={style}
        deleteIcon={processing ? <Spinner color='#7dbb90' style={{height: 15, width: 15, margin: '0 5px 0 -1px'}} small /> : rightIcon || <></>}
        avatar={leftIcon || <></>}
        label={label}
        variant="outlined"
        primarycolor={primaryColor}
        bgcolor={bgColor}
        size="small"
        onClick={e => !processing && onClick && onClick(e)}
        onDelete={e => !processing && onClick && onClick(e)}
      />
    </LightTooltip>
  )
}

export const CorrectingChip = () => {
  return <Default 
    rightIcon={<InsertEmoticon style={{width: 20, height: 20, color: '#FFF'}}/>} 
    onClick={() => {}} 
    label='Correcting' 
    primaryColor={'#FFF'}
    bgColor={'#7dbb90'}
    description='Our certified team is correcting any errors they find with your transcript. It will be read-only until they are complete.' 
  />
}

export const PausedChip = () => 
  <Default 
    label='Paused'
    description={`Your transcript may have paused due to a lack of valid payment card on file, lack of credits, or not enough credits to back multiple files uploading at the same time.`}
  />

export const UpgradeChip = ({processing, id}) => {
  const [collapsed, setCollapsed] = React.useState(false)
  const [shake, setShake] = React.useState(false)
  const [open, setOpen] = React.useState(false)

  setTimeout(() => setCollapsed(true), 5000)
  setTimeout(() => setShake(true), 2000)
    
  return <>
    <Default 
      rightIcon={<InsertEmoticon style={{width: 20, height: 20}}/>} 
      onClick={() => setOpen(true)} 
      className={shake ? 'small-shake' : ''}
      processing={processing || 0}
      collapsed={collapsed || 0}
      label='Improve Accuracy'
      description='Click here to have our certified team correct any errors in your transcript.' 
    />
    
    {open && <UpgradeModal id={id} onClose={() => setOpen(false)}/>}
    
  </>
}

export const PartialChip = () => 
  <Default 
    label='Trimmed'
    description={`Partially complete using remaining promotion minutes. Please, update payment method and re-upload to get entire transcript.`}
  />

export default Default